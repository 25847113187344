import { ReactNode } from "react";
import { Dialog } from "@headlessui/react";

export interface ModalHeaderProps {
  children: ReactNode;
  onClose: () => void;
}

export const ModalHeader = ({ children, onClose }: ModalHeaderProps) => {
  return (
    <div className="flex items-center justify-between mb-4 pb-4 border-b border-gray-650 relative">
      <Dialog.Title
        as="h3"
        className="w-full text-sm text-yellow-550 font-medium leading-6 break-words whitespace-pre-wrap"
        style={{ wordBreak: 'break-word' }}
      >
        {children}
      </Dialog.Title>
      <div className="absolute top-0 right-0">
        <button
          type="button"
          className="text-gray-400 bg-transparent rounded-md hover:text-white focus:"
          onClick={onClose}
        >
          [x]
        </button>
      </div>
    </div>
  );
};
