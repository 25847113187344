// Assets.tsx

import React, { useEffect, useState, useReducer, useMemo, useRef } from "react";
import classNames from "classnames";
import { eventCloud } from "./EventCloud";
import { Asset } from "./interfaces/asset.interface";
import { formatPriceLocale, formatPriceNum } from "./utils/price";
import { BehaviorSubject } from "rxjs";
import { useNavigate, useLocation } from "react-router-dom";
import "./css/Assets.css";

// Reducer function for assets
export const assetsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "UPDATE_ASSETS":
      return action.payload;
    case "UPDATE_ASSET":
        return state.map((asset: Asset) => {
            if (asset.symbol === action.payload.symbol) {
                const isPriceIncreased = action.payload.last_price >= asset.last_price;
                // Add a new property to indicate price direction
                return { ...asset, ...action.payload, price_change_dir: isPriceIncreased ? 'up' : 'down' };
            }
            return asset;
        });
    default:
      return state;
  }
};

interface AssetItemProps {
    asset: Asset;
    handleAssetChange: (asset: Asset) => void;
    selectedAsset: Asset | null;
}

const AssetItem = React.memo(React.forwardRef<HTMLDivElement, AssetItemProps>(({
    asset,
    handleAssetChange,
    selectedAsset
}, ref) => (
    <div ref={ref} key={asset.symbol} className="xl:border-b border-gray-650">
        <input
            type="radio"
            id={asset.symbol}
            name="asset"
            value={asset.symbol}
            checked={asset.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase()}
            onChange={() => handleAssetChange(asset)}
            className="invisible absolute"
        />
        <label
            htmlFor={asset.symbol}
            className={classNames(
                "flex cursor-pointer text-xxs hover:bg-gray-450 hover:bg-opacity-5 ease-out duration-100",
                {
                    "bg-gray-450 bg-opacity-5 box-border select-none":
                        asset.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase(),
                }
            )}
        >
            <div
                className={classNames(
                    "xl:w-3/12 xl:pl-4 pl-2 py-1 pr-2 text-xs font-medium xl:border-r border-gray-650 select-none",
                    {
                        "border-b-2 border-b-white xl:border-l-2 xl:border-b-0 xl:border-l-white xl:border-b-none box-border":
                            asset.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase(),
                    }
                )}
            >
                {asset.symbol.replace(/usd/g, "")}
            </div>

            <div className={classNames("xl:w-4/12 px-2 py-1 text-right border-r border-gray-650 select-none", {
                "border-b-2 border-b-white xl:border-b-0 box-border": asset.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase()
            })}>
                {formatPriceLocale(Number(asset.last_price), asset.decimals)}
                <br />
                <span
                    className={classNames([
                        asset.change >= 0 ? "text-green-550" : "text-red-550",
                    ])}
                >
                    {asset.change >= 0 ? "+" : "-"}
                    {Math.abs(asset.change).toFixed(2)}%
                </span>
            </div>

            <div className="hidden xl:block w-5/12  px-2 py-1 text-right">
                {formatPriceNum(asset.volume, 0)}
            </div>
        </label>
    </div>
)));

const Assets: React.FC = () => {
  const [assets, dispatch] = useReducer(assetsReducer, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const assetRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const defaultAssetSymbol = "btcusd";

  const assetSymbolFromUrl = location.pathname.substring(1).toUpperCase();

  useEffect(() => {
    const subscription = eventCloud.assetsStore.subscribe((newAssets) => {
      if (newAssets.length === 0) return;

      dispatch({ type: "UPDATE_ASSETS", payload: newAssets });
      //console.log("[Assets] Assets updated: ", newAssets);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = eventCloud.selectedAsset.subscribe((asset) => {
      handleAssetChange(asset);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (assets.length > 0 && !selectedAsset) {
      //console.log("[Assets] selectedAsset null", selectedAsset);
      handleUrlAsset(assets);
    }
  }, [assets]);

  useEffect(() => {
    if (assets.length === 0) return;
    const handleNewTrade = (trade: any) => {
      if (assets.length === 0) return;

      const updatedAssets = assets.map((asset: Asset) => {
        if (asset.symbol.toUpperCase() === trade.Asset.toUpperCase()) {
          const newVolume = asset.volume + (trade.Price * trade.Amount) / 1e16;
          let change = 0;
          if (asset.open !== 0) {
            change = ((trade.Price / 1e8 - asset.open) / asset.open) * 100;
          }

          const updatedAsset = {
            ...asset,
            last_price: trade.Price,
            volume: newVolume,
            change: change,
          };
          dispatch({ type: "UPDATE_ASSET", payload: updatedAsset });
        }
        return asset;
      });
    };

    const unsubscribeNewTrade = eventCloud.on("newTrade", handleNewTrade);

    return () => {
      unsubscribeNewTrade();
    };
  }, [assets]);

  const handleAssetChange = (selAsset: any) => {
    if (!selAsset) return;
    if (selAsset.symbol.toLowerCase() !== selectedAsset?.symbol.toLowerCase()) {
      //console.log("handleAssetChange ", selAsset);
      setSelectedAsset(selAsset);
      eventCloud.setSelectedAsset(selAsset);
      navigate(`/${selAsset.symbol.toLowerCase()}`);
    }
  };

    // useEffect(() => {
    //     if (selectedAsset && assetRefs.current[selectedAsset.symbol]) {
    //         assetRefs.current[selectedAsset.symbol]?.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'nearest'
    //         });
    //     }
    // }, [selectedAsset]);

    useEffect(() => {
        if (selectedAsset && assetRefs.current[selectedAsset.symbol]) {
            const element = assetRefs.current[selectedAsset.symbol];
            const container = document.querySelector('.assets-scroll-container-desktop');

            if (element && container) {
                const elementRect = element.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Check if the element is within the visible bounds of the container
                const isVisible = (elementRect.top >= containerRect.top) && (elementRect.bottom <= containerRect.bottom);

                if (!isVisible) {
                    const scrollTop = container.scrollTop;
                    const elementTopWithinContainer = elementRect.top - containerRect.top + scrollTop;
                    const scrollPosition = elementTopWithinContainer - 20; // Adjust '-20' as needed

                    container.scrollTop = scrollPosition;
                }
            }
        }
    }, [selectedAsset]);



    const renderedAssets = useMemo(() => {
        return assets.map((asset: Asset) => (
            <AssetItem
                key={asset.symbol}
                asset={asset}
                handleAssetChange={handleAssetChange}
                selectedAsset={selectedAsset}
                ref={(el: HTMLDivElement | null) => assetRefs.current[asset.symbol] = el}
            />
        ));
    }, [assets, handleAssetChange, selectedAsset]);

  const handleUrlAsset = (newAssets: Asset[]) => {
    console.log("[handleUrlAsset] newAssets ", newAssets);
    let assetSymbolFromUrl = location.pathname.substring(1).toUpperCase();
    console.log("assetSymbolFromUrl", assetSymbolFromUrl, selectedAsset?.symbol);
    if (assetSymbolFromUrl === "") {
      assetSymbolFromUrl = defaultAssetSymbol;
    }
    // console.log(
    //     "[handleUrlAsset] assetSymbolFromUrl ",
    //     location.pathname,
    //     assetSymbolFromUrl
    // );

    const assetExists = newAssets.find(
      (asset) => asset.symbol.toUpperCase() === assetSymbolFromUrl.toUpperCase()
    );

    if (assetExists) {
      //console.log("[handleUrlAsset] assetExists ", assetExists, selectedAsset);
      if (
        assetExists.symbol.toUpperCase() === selectedAsset?.symbol.toUpperCase()
      )
        return;

      if (
        (assetExists && !selectedAsset) ||
        selectedAsset?.symbol !== assetExists.symbol
      ) {
        //console.log("[Assets] setSelectedAsset assetExists", assetExists)
        setSelectedAsset(assetExists);

        eventCloud.setSelectedAsset(assetExists);
        //console.log("[Assets] setSelectedAsset setSelectedAsset", selectedAsset)
      } else {
        const defaultAsset = newAssets.find(
          (newAsset) => newAsset.symbol.toLowerCase() === defaultAssetSymbol
        );

        if (!defaultAsset) {
          console.log("[handleUrlAsset] No asset found");
        } else {
          //console.log("[handleUrlAsset] set default asset ", defaultAsset, selectedAsset);
          setSelectedAsset(defaultAsset);
          eventCloud.setSelectedAsset(defaultAsset);
        }
      }
    } else {
      navigate("/" + defaultAssetSymbol);
    }
  };

  return (
    <div className="assets-container w-full xl:w-60 text-xs border-r border-gray-650 self-stretch ">
      <h2 className="hidden xl:block px-4 py-2 bg-black bg-opacity-30">
        Assets
      </h2>
      <form className='flex flex-col h-full'>
        <div className="hidden xl:flex text-gray-450 text-xxs border-t border-b border-gray-650 bg-gray-750">
          <div className="w-3/12 pl-4 py-1 pr-2 border-r border-gray-650">
            SYMBOL
          </div>
          <div className="w-4/12 py-1 p-2 text-right border-r border-gray-650">
            PRICE/USD
          </div>
          <div className="w-5/12 py-1 p-2 text-right">24 VOLUME/USD</div>
        </div>

        <div className="overflow-y-scroll container-scroll assets-scroll-container-desktop">
          <div className="flex xl:block assets-scroll-container container-scroll">{renderedAssets}</div>
        </div>
      </form>
    </div>
  );
};

export default Assets;
