// TakeProfitStopLossDialog.tsx

import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import { Button } from "../components/Button/Button";
import { eventCloud } from '../EventCloud';
import { ModalHeader } from "../components/Modal/ModalHeader";
import { Position } from "../interfaces/position.interface";

interface TakeProfitStopLossDialogProps {
    position: Position;
    isOpen: boolean;
    asset: string;
    isTakeProfit: boolean;

    onCancel: () => void;
    onConfirm: (price: number, amount: number) => void;
}

const TakeProfitStopLossDialog: React.FC<TakeProfitStopLossDialogProps> = ({ position, isOpen, asset, isTakeProfit, onConfirm, onCancel }) => {
    const [price, setPrice] = useState(formatPrice(Number(position.entry_price), position.decimals));
    const [amount, setAmount] = useState(formatPrice(Number(position.size), position.amount_dec));

    const handleConfirm = () => {
        onConfirm(Number(price)*1e8, Number(amount)*1e8);
    };

    return (
      <div>
        <div className="sm:-mt-5 -mt-1">
          <ModalHeader onClose={onCancel}>
            Set {isTakeProfit ? "Take Profit" : "Stop Loss"}
          </ModalHeader>
        </div>

        <div className='text-xs'>        
            
          <div className="flex justify-between relative">
            <label  className="mt-2 mr-2" htmlFor="amount">Price </label>
            <input
              type="number"
              id="amount"
              autoComplete="off"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className='input-text'
            />

            <div className="flex absolute items-center right-px top-px orderbox-amount-buttons">
              <div className="px-2 text-xxs border-l border-gray-650 text-gray-450 h-3 flex items-center">
                USD
              </div>
            </div>
          </div>
          <div className="pt-4 flex justify-between relative">
            <label className="mt-2 mr-2" htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              value={amount}
              className='input-text'
              autoComplete="off"

              onChange={(e) => setAmount(e.target.value)}
            />

            <div className="pt-7 flex absolute items-center right-px top-px orderbox-amount-buttons">
              <div className="px-2 text-xxs border-l border-gray-650 text-gray-450 h-3 flex items-center">
                {asset}
              </div>
            </div>
          </div>

          

          <div className="flex justify-between mt-6">
            <Button
              variant="green"
              className="w-32"
              onClick={handleConfirm}
              disabled={!amount || !price}
            >
              Confirm
            </Button>
            <Button variant="gray" className="w-32" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
};

const formatPrice = (price: number, decimals: number): string => {
  return (Number(price) / 1e8).toFixed(decimals);
};



export default TakeProfitStopLossDialog;
