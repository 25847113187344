import React, { useEffect, useState, useContext } from 'react';
// Import the context or any method to access EventCloud instance if you're using such a pattern
import { eventCloud } from './EventCloud';

const AppRefreshHandler: React.FC = () => {
    const [previousLaunchTime, setPreviousLaunchTime] = useState<string | null>(null);
    // Assume you have a way to access your EventCloud instance, e.g., via useContext or props
    // const eventCloud = useContext(EventCloudContext);

    useEffect(() => {
        const subscription = eventCloud.paramsStore.subscribe((params) => {
            if (params) {
                const currentLaunchTime = params.backend_launch_time;
                // Check if previousLaunchTime is not null and different from currentLaunchTime
                if (previousLaunchTime && previousLaunchTime !== currentLaunchTime) {
                    window.location.reload(); // Refresh the page
                }
                // Update the previousLaunchTime state with the current backend_launch_time
                setPreviousLaunchTime(currentLaunchTime);
            }
        });

        // Cleanup subscription on component unmount
        return () => subscription.unsubscribe();
    }, [eventCloud.paramsStore, previousLaunchTime]);

    return null; // This component doesn't render anything
};

export default AppRefreshHandler;
