import { eventCloud } from "../EventCloud";
declare global {
    interface Window {
        ethereum: any;
    }
}

export async function switchNetwork(chainName: string): Promise<boolean> {
    if (!window.ethereum) {
        console.error("Ethereum object doesn't exist!");
        return false; // Ensure Ethereum object is available
    }
    let chainId = 0
    switch (chainName) {
        case "Ethereum":
            chainId = 1;
            break;

        case "Polygon":
            chainId = 137;
            break;

        case "Avalanche":
            chainId = 43114;
            break;

        case "Optimism":
            chainId = 10;
            break;

        case "Arbitrum":
            chainId = 42161;
            break;

        case "Base":
            chainId = 8453;
            break;

        case "BSC":
            chainId = 56;
            break;
    }

    try {
        eventCloud.emit("waitingMetaMaskConfirmation", {}); 
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        eventCloud.emit("unWaitMetaMask", {}); 
        return true; // Successfully switched network
    } catch (error: any) {
        eventCloud.emit("unWaitMetaMask", {}); 
        if (error.code === 4902) {
            console.error('The network has not been added to MetaMask.');
        } else if (error.code === 4001) {
            console.error('User rejected the request.');
        } else {
            console.error('Failed to switch the network:', error);
        }
        return false; // Failed to switch network
    }
}
