// UpdateMargin.ts

import React, { useState, useEffect } from "react";
import { Wallet } from "../interfaces/wallet.interface";
import Web3 from 'web3';
import { eventCloud } from './../EventCloud';
import { ethers } from 'ethers';
import { keccak256, defaultAbiCoder } from 'ethers/lib/utils';
import { Sign } from './Sign';

export const handleUpdateMargin = async (
    positionHash: string, 
    increase: boolean, 
    amount: string,
    currentMargin: string,
) => {
    let web3;    

	console.log("[handleUpdateMargin] ", positionHash, increase, amount, currentMargin);
    

    const selectedWallet = eventCloud.selectedWallet.getValue();  
    
    if (!selectedWallet) return;

    const newMargin = increase ? (Number(currentMargin) + Number(amount)*1e8).toFixed(0) : (Number(currentMargin) - Number(amount)*1e8).toFixed(0);

    const contract_address = eventCloud.params.trading_contract;

    const updateMarginHash = ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(
        [
            'address',
            'bytes32',        
            'uint256'
        ],
        [
            contract_address,
            positionHash,
            newMargin
        ]   
    ));

    console.log("[handleUpdateMargin] newMargin ", newMargin, contract_address, positionHash);
    
    const signatureResult = await Sign(updateMarginHash, selectedWallet);

    // Check if the signatureResult is null
    if (!signatureResult) {
        console.error("Signature process was not completed.");
        return false;
    }

    const { v, r, s } = signatureResult;

    eventCloud.updateMargin({ 
    	positionHash,
        updateMarginHash,
        newMargin: newMargin.toString(),
    	v, 
    	r, 
    	s
    });
};

export default handleUpdateMargin;