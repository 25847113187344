// web3Provider.ts

export const getEthProvider = (chain: string) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment == "dev") {
        return "http://localhost:7545";
    } else if (environment == "live") {
        switch (chain) {
            case "Ethereum":
                return process.env.REACT_APP_ETH_NODE_ENDPOINT;
                break;

            case "Polygon":
                return process.env.REACT_APP_POLYGON_NODE_ENDPOINT;
                break;

            case "Avalanche":
                return process.env.REACT_APP_AVAC_NODE_ENDPOINT;
                break;

            case "Optimism":
                return process.env.REACT_APP_OPTIMISM_NODE_ENDPOINT;
                break;

            case "Arbitrum":
                return process.env.REACT_APP_ARBITRUM_NODE_ENDPOINT;
                break;

            case "Base":
                return process.env.REACT_APP_BASE_NODE_ENDPOINT;
                break;

            case "BSC":
                return process.env.REACT_APP_BSC_NODE_ENDPOINT;
                break;
        }
        
    }
}

export default getEthProvider;
