// RiskLimitConfirmDialog.tsx

import React, { useState } from "react";
import { Button } from "../components/Button/Button";
import { ModalHeader } from "../components/Modal/ModalHeader";
import { eventCloud } from "../EventCloud";


interface RiskLimitConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void; // Callback function for when the user confirms the cancellation
  newRiskLimit: number,
  oldRiskLimit: number,
}

const RiskLimitConfirmDialog: React.FC<RiskLimitConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  newRiskLimit, 
  oldRiskLimit,
}) => {
  return (
    <>
        <div>
            <div>
                <div className="-mt-4">
                    <ModalHeader onClose={onClose}>Confirm Risk Limit Change</ModalHeader>
                </div>

                <div className="text-xs">
                    Changing the risk limit will affect your maintenance margin requirements for new and existing positions
                </div>
                <div className="text-xs mt-8 text-gray-450">
                    <div className="justify-between w-full flex justify-between">
                        <span>Current Risk Limit:</span> 
                        <span>{formatNumLocale(oldRiskLimit * 1000, 0)} USD</span>
                    </div>
                    <div className="justify-between w-full flex justify-between">
                        <span>Current MM Multiplier:</span> 
                        <span><span className="text-xxxs">x</span>{getMMMultiplier(oldRiskLimit)}</span>
                    </div>                     
                </div>
                <div className="text-xs text-yellow-550 mt-2 w-full ">
                    <div className="justify-between w-full flex justify-between">
                        <span>New Risk Limit: </span>
                        <span>{formatNumLocale(newRiskLimit * 1000, 0)} USD</span>
                    </div>
                    <div className="justify-between w-full flex justify-between">
                        <span>New MM Multiplier:</span>
                        <span><span className="text-xxxs">x</span>{getMMMultiplier(newRiskLimit)}</span>
                    </div>
                </div>
            </div>
        </div>
          
        <div className="mt-8">
            <Button
                variant="yellow"
                className="w-full"
                color="primary"
                onClick={onConfirm}
            >
                Confirm Change
            </Button>
        </div>
    </>
  );
};

const getMMMultiplier = (riskLimit: number): string => {
    switch (riskLimit) {
        case 500: return "1.00";
        case 1000: return "1.05";
        case 2500: return "1.10";
        case 5000: return "1.15";
        case 10000: return "1.20";
        case 50000: return "1.25";
    }

    return ""
}

const formatNumLocale = (price: number, decimals: number): string => {
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(price);
};

export default RiskLimitConfirmDialog;
