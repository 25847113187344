// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class ConciseOrder {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ConciseOrder {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsConciseOrder(bb:flatbuffers.ByteBuffer, obj?:ConciseOrder):ConciseOrder {
  return (obj || new ConciseOrder()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsConciseOrder(bb:flatbuffers.ByteBuffer, obj?:ConciseOrder):ConciseOrder {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ConciseOrder()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

price():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

amount():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

value():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

total():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

isYours():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startConciseOrder(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addPrice(builder:flatbuffers.Builder, price:bigint) {
  builder.addFieldInt64(0, price, BigInt('0'));
}

static addAmount(builder:flatbuffers.Builder, amount:bigint) {
  builder.addFieldInt64(1, amount, BigInt('0'));
}

static addValue(builder:flatbuffers.Builder, value:bigint) {
  builder.addFieldInt64(2, value, BigInt('0'));
}

static addTotal(builder:flatbuffers.Builder, total:bigint) {
  builder.addFieldInt64(3, total, BigInt('0'));
}

static addIsYours(builder:flatbuffers.Builder, isYours:boolean) {
  builder.addFieldInt8(4, +isYours, +false);
}

static endConciseOrder(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createConciseOrder(builder:flatbuffers.Builder, price:bigint, amount:bigint, value:bigint, total:bigint, isYours:boolean):flatbuffers.Offset {
  ConciseOrder.startConciseOrder(builder);
  ConciseOrder.addPrice(builder, price);
  ConciseOrder.addAmount(builder, amount);
  ConciseOrder.addValue(builder, value);
  ConciseOrder.addTotal(builder, total);
  ConciseOrder.addIsYours(builder, isYours);
  return ConciseOrder.endConciseOrder(builder);
}
}
