// UpdateMarginDialog.tsx

import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import { Button } from "../components/Button/Button";
import { handleUpdateMargin } from '../solidity/UpdateMargin';
import { eventCloud } from '../EventCloud';
import { ModalHeader } from "../components/Modal/ModalHeader";
import { Position } from "../interfaces/position.interface";

interface UpdateMarginDialogProps {
    position: Position;
    isOpen: boolean;

    onCancel: () => void;
    onConfirm: () => void;
}

const UpdateMarginDialog: React.FC<UpdateMarginDialogProps> = ({ position, isOpen, onConfirm, onCancel }) => {
    const [updateDirection, setUpdateDirection] = useState(true);
    const [amount, setAmount] = useState('');

    const initiateUpdateMargin = async () => {
        console.log(`[UpdateMarginDialog] position=${position} updateDirection=${updateDirection} amount=${amount}`);

        try {
            await handleUpdateMargin(
                position.hash,
                updateDirection,
                amount,
                position.margin,
            );

            onConfirm();
        } catch (error) {
            console.error('Update Margin failed:', error);
        }
    };

    const handleMax = (amount: string) => {
        console.log("[handleMax] amount", amount);
        setAmount(amount);
    }

    return (
      <div>
        <div className="sm:-mt-5 -mt-1">
          <ModalHeader onClose={onCancel}>Update Margin</ModalHeader>
        </div>

        <div className='text-xs'>
          {/*<div className='text-center'>
            Position Hash: {positionHash}
          </div>*/}

          <div className="flex pt-1 pb-4 mb-2">
            <label className="w-1/2">
              <input
                type="radio"
                id="increase"
                name="updateDirection"
                value="Increase"
                checked={updateDirection}
                onChange={() => setUpdateDirection(true)}
                className="invisible absolute"
              />
              <div
                className={classNames(
                  "w-full h-10 flex items-center justify-center cursor-pointer box-border text-center px-4 py-2 text-xs text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650",
                  { "border-b-2 border-b-white": updateDirection }
                )}
              >
                Increase
              </div>
            </label>

            <label className="w-1/2 -ml-px">
              <input
                type="radio"
                id="decrease"
                name="updateDirection"
                value="Decrease"
                checked={!updateDirection}
                onChange={() => setUpdateDirection(false)}
                className="invisible absolute"
              />
              <div
                className={classNames(
                  "w-full h-10 flex items-center justify-center cursor-pointer box-border text-center px-4 py-2 text-xs text-gray-150 hover:text-white disabled:text-gray-450 bg-gray-750 hover:bg-gray-740 border border-gray-650",
                  { "border-b-2 border-b-white": !updateDirection }
                )}
              >
                Decrease
              </div>
            </label>
          </div>

          {/*<input
          type="radio"
          id="increase"
          name="updateDirection"
          value="Increase"
          checked={updateDirection}
          onChange={() => setUpdateDirection(true)}
        />
        <label htmlFor="increase">Increase</label>

        <input
          type="radio"
          id="decrease"
          name="updateDirection"
          value="Decrease"
          checked={!updateDirection}
          onChange={() => setUpdateDirection(false)}
        />
        <label htmlFor="decrease">Decrease</label>*/}
          <div>
          {updateDirection ? (
            <div>
              Available Balance: {formatBalance(
              Number(eventCloud.getBalance(position.base_token)?.available_balance),
              Number(position.margin_dec))
            } {position.margin_symbol}
            <Button
              type="button"
              variant="gray"
              size="small"
              onClick={() => handleMax(formatBalance(
              Number(eventCloud.getBalance(position.base_token)?.available_balance),
              Number(position.margin_dec)))}
              className="ml-2"
            >
              MAX
            </Button>
            </div>

          ) : (
            <div>
              Available Margin: {formatMargin(
              calcMaxDecreaseMargin(position, position.realized_pnl, position.unrealized_pnl),
              Number(position.margin_dec))
            } {position.margin_symbol}
            <Button
              type="button"
              variant="gray"
              size="small"
              onClick={() => handleMax(formatMargin(
              calcMaxDecreaseMargin(position, position.realized_pnl, position.unrealized_pnl),
              Number(position.margin_dec)))}
              className="ml-2"
            >
              MAX
            </Button>
            </div>
          )}
            
          </div>
          <div className='pt-4'>
            <label htmlFor="amount">Amount:</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className='input-text'
            />
          </div>

          <div className="flex justify-between mt-6">
            <Button
              variant="green"
              className="w-32"
              onClick={initiateUpdateMargin}
              disabled={!amount}
            >
              Confirm
            </Button>
            <Button variant="gray" className="w-32" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
};

const calcMaxDecreaseMargin = (position: Position, rpnl: number, upnl: number): number => {
    //console.log(`[calcMaxDecreaseMargin] position.mark_price=${position.mark_price} position.entry_price=${position.entry_price}`)
    if ((position.side && Number(position.mark_price) > Number(position.entry_price)/1e8) || 
        (!position.side && Number(position.mark_price) < Number(position.entry_price)/1e8)) {
        return Number(position.margin)*0.95;
    }

    return (Number(position.margin) + (position.realized_pnl > 0 ? 0 : position.realized_pnl) + (position.unrealized_pnl > 0 ? 0 : position.unrealized_pnl))*0.95;
}

const formatBalance = (amount: number, amount_dec: number): string => {
    return (Number(amount) / 1e18).toFixed(amount_dec);
};

const formatMargin = (amount: number, amount_dec: number): string => {
    return (Number(amount) / 1e8).toFixed(amount_dec);
};



export default UpdateMarginDialog;
