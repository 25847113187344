import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Positions from "../Positions";
import Orders from "../Orders";
import PositionsClosed from "../PositionsClosed";
import OrdersClosed from "../OrdersClosed";
import TradesUser from "../TradesUser";
import Funds from "../Funds";
import { eventCloud } from "../EventCloud";
import { Position } from "../interfaces/position.interface";
import { Order } from "../interfaces/order.interface";
import { Trade } from "../interfaces/trade.interface";
import { Fund } from "../interfaces/fund.interface";
import useWindowSize from "../hooks/useWindowSize";

import "../css/Positions.css";

const activePositionTabs = [
  "Positions",
  "Orders",
  "Closed Positions",
  "Closed Orders",
];

const mobileOrdersTabs = ["Orders", "Closed Orders", "Trades", "Funds"];

const mobilePositions = ["Positions", "Closed Positions"];

export const PositionsContainer = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  const [activePositionTab, setActivePositionTab] = useState(0);
  const [activePositionMobileTab, setActivePositionMobileTab] = useState(0);
  const [activePositionH1000Tab, setActivePositionH1000Tab] = useState(0);
  const [activeOrderMobileTab, setActiveOrderMobileTab] = useState(0);


  const [activePositionH1400W2400Tab1, setActivePositionH1400W2400Tab1] = useState(0);
  const [activePositionH1400W2400Tab2, setActivePositionH1400W2400Tab2] = useState(0);
  const [activePositionH1400W2400Tab3, setActivePositionH1400W2400Tab3] = useState(0);

  const [positions, setPositions] = useState<Position[]>([]);
  const [closedPositions, setClosedPositions] = useState<Position[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersClosed, setOrdersClosed] = useState<Order[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);
  const [funds, setFunds] = useState<Fund[]>([]);
  

  useEffect(() => {
    // Subscribe
    const subPosition = eventCloud.positionsStore.subscribe((positions) => {
      if (!positions || positions.length === 0) {
        setPositions([]); 
        return;
      }
      setPositions(positions);
    });

    const subClosedPositions = eventCloud.positionsClosedStore.subscribe((positions) => {
      if (!positions || positions.length === 0) {
        setClosedPositions([]); 
        return;
      }
      setClosedPositions(positions);
    });

    const subOrders = eventCloud.ordersStore.subscribe((orders) => {
      if (!orders || orders.length === 0) {
        setOrders([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setOrders(orders);
    });

    const subOrdersClosed = eventCloud.ordersClosedStore.subscribe((orders) => {
      if (!orders || orders.length === 0) {
        setOrdersClosed([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setOrdersClosed(orders);
    });

    const subTrades = eventCloud.tradesUserStore.subscribe((trades) => {
      if (!trades || trades.length === 0) {
        setTrades([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setTrades(trades);
    });

    const subFunds = eventCloud.fundsStore.subscribe((funds) => {
      if (!funds || funds.length === 0) {
        setFunds([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setFunds(funds);
    });

    return () => {
      subPosition.unsubscribe();
      subClosedPositions.unsubscribe();
      subOrders.unsubscribe();
      subOrdersClosed.unsubscribe();
      subTrades.unsubscribe();
      subFunds.unsubscribe();
    };
  }, []);

  return windowWidth > 2400 && windowHeight > 1400 ? (
    <div
      className="positions-container-wrapper flex text-white bg-black relative"
      // style={{ background: "#181B25" }}
    >
      <div className="positions-container-positions">
        <div className="flex items-center positions-container-header">
          {activePositionTabs.map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activePositionTab,
                }
              )}
              onClick={() => {
                setActivePositionTab(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionTab,
                  })}
                >
                  {" "}
                  [{positions.length}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionTab,
                  })}
                >
                  {" "}
                  [{orders.length}]
                </span>
              )}

              {index === activePositionTab && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>
        <div className="positions-h-1400-left-wrapper">
          <div
            className={classNames(
              "positions-container-wrapper-small-24p",
            )}
          >
            <Positions />
          </div>
          <div
            className={classNames(
              "positions-container-wrapper-small-38p",
            )}
          >
            <Orders />
          </div>
          <div
            className={classNames(
              "positions-container-wrapper-small-38p"
            )}
          >
            <PositionsClosed />
          </div>
        </div>
      </div>

      <div className="positions-container-trades">
        <div
          className={classNames(
            "positions-container-wrapper-small-24p"
          )}
        >
          <OrdersClosed />
        </div>
        <div className="positions-container-wrapper-small-38p">
          <TradesUser />
        </div>
        <div className="positions-container-wrapper-small-38p">
          <Funds />
        </div>
      </div>

      <div className="border-b border-gray-650 absolute bottom-0 w-full" />
    </div>
  ) : windowHeight > 1400 && windowWidth <= 2400 ? (
    <div className="text-white positions-container-wrapper-small">
      <div className="positions-container-wrapper-small-24p">
        <div className="flex items-center">
          {mobilePositions.map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activePositionH1400W2400Tab1,
                }
              )}
              onClick={() => {
                setActivePositionH1400W2400Tab1(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1400W2400Tab1,
                  })}
                >
                  {" "}
                  [{positions.length}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1400W2400Tab1,
                  })}
                >
                  {" "}
                  [{closedPositions.length}]
                </span>
              )}

              {index === activePositionH1400W2400Tab1 && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>
        {activePositionH1400W2400Tab1 === 0 && <Positions />}
        {activePositionH1400W2400Tab1 === 1 && <PositionsClosed />}
      </div>

      <div className="positions-container-wrapper-small-38p">
        <div className="flex items-center">
          {mobileOrdersTabs.slice(0,2).map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activePositionH1400W2400Tab2,
                }
              )}
              onClick={() => {
                setActivePositionH1400W2400Tab2(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1400W2400Tab2,
                  })}
                >
                  {" "}
                  [{orders.length}]
                </span>
              )}

              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1400W2400Tab2,
                  })}
                >
                  {" "}
                  [{ordersClosed.length < 100 ? ordersClosed.length : "100+"}]
                </span>
              )}

              {index === activePositionH1400W2400Tab2 && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>

        {activePositionH1400W2400Tab2 === 0 && <Orders />}
        {activePositionH1400W2400Tab2 === 1 && <OrdersClosed />}
      </div>

      <div className="positions-container-wrapper-small-38p">
        <div className="flex items-center">
          {mobileOrdersTabs.slice(2,4).map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activePositionH1400W2400Tab3,
                }
              )}
              onClick={() => {
                setActivePositionH1400W2400Tab3(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1400W2400Tab3,
                  })}
                >
                  {" "}
                  [{trades.length < 100 ? trades.length : "100+"}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1400W2400Tab3,
                  })}
                >
                  {" "}
                  [{funds.length < 100 ? funds.length : "100+"}]
                </span>
              )}

              {index === activePositionH1400W2400Tab3 && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>

        {activePositionH1400W2400Tab3 === 0 && <TradesUser />}
        {activePositionH1400W2400Tab3 === 1 && <Funds />}
      </div>
    </div>
  ) : windowHeight < 1000 && windowWidth > 1050 ? (
    <div className="text-white positions-container-wrapper-small">
      <div className="flex items-center">
        {[...mobilePositions, ...mobileOrdersTabs].map((label, index) => (
          <button
            key={index}
            className={classNames(
              "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
              {
                "text-white": index === activePositionH1000Tab,
              }
            )}
            onClick={() => {
              setActivePositionH1000Tab(index);
            }}
          >
            {label}
            {index === 0 && (
              <span
                className={classNames({
                  "text-yellow-550": index === activePositionH1000Tab,
                })}
              >
                {" "}
                [{positions.length}]
              </span>
            )}
            {index === 1 && (
              <span
                className={classNames({
                  "text-yellow-550": index === activePositionH1000Tab,
                })}
              >
                {" "}
                [{closedPositions.length}]
              </span>
            )}
            {index === 2 && (
              <span
                className={classNames({
                  "text-yellow-550": index === activePositionH1000Tab,
                })}
              >
                {" "}
                [{orders.length}]
              </span>
            )}
            {index === 3 && (
              <span
                className={classNames({
                  "text-yellow-550": index === activePositionH1000Tab,
                })}
              >
                {" "}
                [{ordersClosed.length < 100 ? ordersClosed.length : "100+"}]
              </span>
            )}
            {index === 4 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1000Tab,
                  })}
                >
                  {" "}
                  [{trades.length < 100 ? trades.length : "100+"}]
                </span>
              )}
              {index === 5 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionH1000Tab,
                  })}
                >
                  {" "}
                  [{funds.length < 100 ? funds.length : "100+"}]
                </span>
              )}

            {index === activePositionH1000Tab && (
              <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
            )}
          </button>
        ))}
      </div>
      {activePositionH1000Tab === 0 && <Positions />}
      {activePositionH1000Tab === 1 && <PositionsClosed />}
      {activePositionH1000Tab === 2 && <Orders />}
      {activePositionH1000Tab === 3 && <OrdersClosed />}
      {activePositionH1000Tab === 4 && <TradesUser />}
      {activePositionH1000Tab === 5 && <Funds />}
    </div>
  ) : (
    <div className="text-white positions-container-wrapper-small">
      <div className="positions-container-wrapper-small-1/2 first">
        <div className="flex items-center">
          {mobilePositions.map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activePositionMobileTab,
                }
              )}
              onClick={() => {
                setActivePositionMobileTab(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionMobileTab,
                  })}
                >
                  {" "}
                  [{positions.length < 100 ? positions.length : "100+"}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionMobileTab,
                  })}
                >
                  {" "}
                  [{closedPositions.length < 100 ? closedPositions.length : "100+"}]
                </span>
              )}

              {index === activePositionMobileTab && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>
        {activePositionMobileTab === 0 && <Positions />}
        {activePositionMobileTab === 1 && <PositionsClosed />}
      </div>

      <div className="positions-container-wrapper-small-1/2 second">
        <div className="flex items-center">
          {mobileOrdersTabs.map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activeOrderMobileTab,
                }
              )}
              onClick={() => {
                setActiveOrderMobileTab(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{orders.length < 100 ? orders.length : "100+"}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{ordersClosed.length < 100 ? ordersClosed.length : "100+"}]
                </span>
              )}
              {index === 2 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{trades.length < 100 ? trades.length : "100+"}]
                </span>
              )}
              {index === 3 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{funds.length < 100 ? funds.length : "100+"}]
                </span>
              )}
              {index === activeOrderMobileTab && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>

        {activeOrderMobileTab === 0 && <Orders />}
        {activeOrderMobileTab === 1 && <OrdersClosed />}
        {activeOrderMobileTab === 2 && <TradesUser />}
        {activeOrderMobileTab === 3 && <Funds />}
      </div>
    </div>
  );
};
