// Sign.ts

import Web3 from 'web3';
import { getEthProvider } from '../helpers/web3Provider';
import { requestAccountAccess } from '../helpers/requestAccountAccess';
import { Wallet } from '../interfaces/wallet.interface';
import { eventCloud } from './../EventCloud';

export const Sign = async (hash: string, wallet: Wallet): Promise<{v: number, r: string, s: string} | null> => {
	console.log(`[Sign] hash=${hash}, wallet=`, wallet);

    try {
        let signature;
        let v: number, r: string, s: string;
        let web3;  

        if (!wallet.isMetaMask && wallet.privateKey) {
            web3 = new Web3(getEthProvider("Ethereum")); 
            // Sign using the provided private key
            // Ensure privateKey is defined before using it
            const privateKey = wallet.privateKey;
            if (privateKey) {
                const signedData = web3.eth.accounts.sign(hash, privateKey);
                signature = signedData.signature;
            } else {
                throw new Error("Private key is undefined");
            }
        } else {      
            eventCloud.emit("waitingMetaMaskConfirmation", {}); 

            var success = await requestAccountAccess();
            if (!success) {
                throw new Error("Account access not granted");
            }      

            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                web3 = new Web3(window.ethereum);
            } catch (error) {
                console.error('User denied account access:', error);
                return null; // Exit function if user denied access
            }
            // Sign using MetaMask
            try {
                signature = await web3.eth.personal.sign(hash, wallet.address, '');
                eventCloud.emit("unWaitMetaMask", {});   
            } catch (signError: any) {
                eventCloud.emit("unWaitMetaMask", {});   
                if (signError.code === 4001) {
                    console.error('User denied message signature:', signError);
                } else {
                    console.error('Error during signing:', signError);
                }
                return null; // Exit the function
            }
        } 

        // Split the signature into r, s, and v
        const sig = signature.slice(2);  // Remove the 0x prefix
        r = `0x${sig.slice(0, 64)}`;
        s = `0x${sig.slice(64, 128)}`;
        v = parseInt(`0x${sig.slice(128, 130)}`, 16);

        // Adjust v if necessary
        if (v < 27) v += 27;

        return {v,r,s};
    } catch (error) {
        console.error('Error signing message:', error);
        return null;
    }
};

export default Sign;