// getExplorer.ts

export function getExplorer(network: string): string {
    switch (network) {
        case "Ethereum":
            return "https://etherscan.io/"

        case "Tron":
            return "https://tronscan.org/#/"

        case "Polygon":
            return "https://polygonscan.com/"

        case "Bitcoin":
            return "https://blockstream.info/"

        case "Avalanche":
            return "https://snowtrace.io/"

        case "Optimism":
           	return "https://optimistic.etherscan.io/"

        case "Arbitrum":
            return "https://arbiscan.io/"

        case "Base":
            return "https://basescan.org/"

        case "BSC":
            return "https://bscscan.com/"

        case "Solana":
            return "https://solscan.io/"

        case "Ton":
            return "https://tonviewer.com/"
    }

    return "";
	
}

