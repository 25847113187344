import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Positions from "../../../Positions";
import Orders from "../../../Orders";
import PositionsClosed from "../../../PositionsClosed";
import OrdersClosed from "../../../OrdersClosed";
import TradesUser from "../../../TradesUser";
import Funds from "../../../Funds";
import { eventCloud } from "../../../EventCloud";
import { Position } from "../../../interfaces/position.interface";
import { Order } from "../../../interfaces/order.interface";
import { Trade } from "../../../interfaces/trade.interface";
import { Fund } from "../../../interfaces/fund.interface";
import useWindowSize from "../../../hooks/useWindowSize";

import "../../../css/Positions.css";

const activePositionTabs = [
  "Positions",
  "Orders",
  "Closed Positions",
  "Closed Orders",
];

const mobileOrdersTabs = ["Orders", "Closed Orders", "Trades", "Funds"];

const mobilePositions = ["Positions", "Closed Positions"];

export const PositionsContainer = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  const [activePositionTab, setActivePositionTab] = useState(0);
  const [activePositionMobileTab, setActivePositionMobileTab] = useState(0);

  const [positions, setPositions] = useState<Position[]>([]);
  const [closedPositions, setClosedPositions] = useState<Position[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersClosed, setOrdersClosed] = useState<Order[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);
  const [funds, setFunds] = useState<Fund[]>([]);

  useEffect(() => {
    // Subscribe
    const subPosition = eventCloud.positionsStore.subscribe((positions) => {
      if (!positions || positions.length === 0) {
        setPositions([]);
        return;
      }
      setPositions(positions);
    });

    const subClosedPositions = eventCloud.positionsClosedStore.subscribe(
      (positions) => {
        if (!positions || positions.length === 0) {
          setClosedPositions([]);
          return;
        }
        setClosedPositions(positions);
      }
    );

    const subOrders = eventCloud.ordersStore.subscribe((orders) => {
      if (!orders || orders.length === 0) {
        setOrders([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setOrders(orders);
    });

    const subOrdersClosed = eventCloud.ordersClosedStore.subscribe((orders) => {
      if (!orders || orders.length === 0) {
        setOrdersClosed([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setOrdersClosed(orders);
    });

    const subTrades = eventCloud.tradesUserStore.subscribe((trades) => {
      if (!trades || trades.length === 0) {
        setTrades([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setTrades(trades);
    });

    const subFunds = eventCloud.fundsStore.subscribe((funds) => {
      if (!funds || funds.length === 0) {
        setFunds([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setFunds(funds);
    });

    return () => {
      subPosition.unsubscribe();
      subClosedPositions.unsubscribe();
      subOrders.unsubscribe();
      subOrdersClosed.unsubscribe();
      subTrades.unsubscribe();
      subFunds.unsubscribe();
    };
  }, []);

  return (
    <div className={classNames("positions-container-wrapper-small w-full isLite text-white px-2 sm:px-6 mx-auto")} style={{ maxWidth:'1440px' }}>
      <div className="container-scroll mb-1 md:mb-0" style={{ overflowY: 'hidden' }}>
        <div className="flex items-center" style={{ minWidth: "660px" }}>
          {[...mobilePositions, ...mobileOrdersTabs].map((label, index) => (
            <button
              key={index}
              className={classNames(
                "py-3 px-2 lg:px-3 xl:px-4 first:pl-0 text-gray-450 bg-black bg-opacity-30 font-medium text-xs md:text-sm lg:text-base xl:text-xl hover:text-white relative",
                {
                  "text-white": index === activePositionTab,
                }
              )}
              onClick={() => {
                setActivePositionTab(index);
              }}
            >
              {label}
              {[
                positions,
                closedPositions,
                orders,
                ordersClosed,
                trades,
                funds,
              ].map((item, indexItem) =>
                index === indexItem ? (
                  <span
                    className={classNames({
                      "text-yellow-550": index === activePositionTab,
                      "text-gray-450": index !== activePositionTab,
                    })}
                  >
                    [{item.length < 100 ? item.length : "100+"}]
                  </span>
                ) : null
              )}
            </button>
          ))}
        </div>
      </div>
      {activePositionTab === 0 && <Positions isLite />}
      {activePositionTab === 1 && <PositionsClosed isLite />}
      {activePositionTab === 2 && <Orders isLite />}
      {activePositionTab === 3 && <OrdersClosed isLite />}
      {activePositionTab === 4 && <TradesUser isLite />}
      {activePositionTab === 5 && <Funds isLite />}
    </div>
    /*<div className="text-white positions-container-wrapper-small">
      <div className="positions-container-wrapper-small-1/2 first">
        <div className="flex items-center">
          {mobilePositions.map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activePositionMobileTab,
                }
              )}
              onClick={() => {
                setActivePositionMobileTab(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionMobileTab,
                  })}
                >
                  {" "}
                  [{positions.length < 100 ? positions.length : "100+"}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activePositionMobileTab,
                  })}
                >
                  {" "}
                  [
                  {closedPositions.length < 100
                    ? closedPositions.length
                    : "100+"}
                  ]
                </span>
              )}

              {index === activePositionMobileTab && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>
        {activePositionMobileTab === 0 && <Positions />}
        {activePositionMobileTab === 1 && <PositionsClosed />}
      </div>

      <div className="positions-container-wrapper-small-1/2 second">
        <div className="flex items-center">
          {mobileOrdersTabs.map((label, index) => (
            <button
              key={index}
              className={classNames(
                "px-4 py-2 text-gray-450 bg-black bg-opacity-30 border-gray-650 font-medium text-xxs sm:text-xs border-r border-gray-650 hover:bg-opacity-10 hover:bg-white relative",
                {
                  "text-white": index === activeOrderMobileTab,
                }
              )}
              onClick={() => {
                setActiveOrderMobileTab(index);
              }}
            >
              {label}
              {index === 0 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{orders.length < 100 ? orders.length : "100+"}]
                </span>
              )}
              {index === 1 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{ordersClosed.length < 100 ? ordersClosed.length : "100+"}]
                </span>
              )}
              {index === 2 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{trades.length < 100 ? trades.length : "100+"}]
                </span>
              )}
              {index === 3 && (
                <span
                  className={classNames({
                    "text-yellow-550": index === activeOrderMobileTab,
                  })}
                >
                  {" "}
                  [{funds.length < 100 ? funds.length : "100+"}]
                </span>
              )}
              {index === activeOrderMobileTab && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white" />
              )}
            </button>
          ))}
        </div>

        {activeOrderMobileTab === 0 && <Orders />}
        {activeOrderMobileTab === 1 && <OrdersClosed />}
        {activeOrderMobileTab === 2 && <TradesUser />}
        {activeOrderMobileTab === 3 && <Funds />}
      </div>
    </div>*/
  );
};
