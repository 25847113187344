import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

// import { EventCloudProvider } from './EventCloudContext';
import { eventCloud } from "./EventCloud";
import "./css/font.css";
import "./css/App.css";
import "./css/OrderTable.css";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { TVChartContainer } from "./Chart";

import { Header } from "./Header";

import MarginCurrencies from "./MarginCurrencies";
import Balances from "./Balances";
import Wallets from "./Wallets";
import Assets from "./Assets";
import OrderBox from "./OrderBox";
import Trades from "./Trades";
import OrderBook from "./OrderBook";
import AppRefreshHandler from "./AppRefreshHandler";
import { PositionsContainer } from "./components/PositionsContainer";
import ChartResolutionSelector from "./ChartResolutionSelector";
import LiteVersion from "./lite/LiteVersion";

import { TopConnectionComponent } from "./components/TopConnection/TopConnection";

import { MetamaskPopup } from "./components/MetamaskPopup/MetamaskPopup";
import { Homepage } from "./components/Homepage/Homepage";

import { ReactComponent as IconLogo } from "./assets/icons/svg/logo.svg";

import useWindowSize from './hooks/useWindowSize';


const App = () => {
  const isRootPath = location.pathname === "/";
  const storedWallets = localStorage.getItem("wallets");
  const [windowWidth] = useWindowSize();
  const [isWaitingConfirmation, setWaitingConfirmation] = useState(false);
  const [isHomepageOpen, setHomepageOpen] = useState(!storedWallets || isRootPath);
  const [isLiteVersion, setLiteVersion] = useState(
    !(localStorage.getItem("dmex.isLiteVersion") == "false"));

  useEffect(() => {
    const handleMetMaskWait = (val: any) => {
      setWaitingConfirmation(true);
    };

    const unsubscribeWaitingMetaMask = eventCloud.on(
      "waitingMetaMaskConfirmation",
      handleMetMaskWait
    );

    const handleMetMaskUnWait = (val: any) => {
      setWaitingConfirmation(false);
    };

    const unsubscribeUnWaitingMetaMask = eventCloud.on(
      "unWaitMetaMask",
      handleMetMaskUnWait
    );

    return () => {
      unsubscribeWaitingMetaMask();
      unsubscribeUnWaitingMetaMask();
    };
  }, []);

  const handleMetaMaskPopupClose = () => {
    setWaitingConfirmation(false);
  };

  const handleHomepageClose = () => {
    setHomepageOpen(false);
    eventCloud.sendEvent("pass_homepage", "");
  };

  const handleSetVersion = (isLite: boolean) => {
    setLiteVersion(isLite);
    localStorage.setItem("dmex.isLiteVersion", isLite.toString());

    if (isLite) {
      eventCloud.sendEvent("ver_lite", "")
    } else {
      eventCloud.sendEvent("ver_pro", "")
    }
  };

  return (
    <div className="App mx-auto">
      <Homepage open={isHomepageOpen} onClose={handleHomepageClose} windowWidth={windowWidth} setLiteVersion={handleSetVersion} />
      {!isHomepageOpen && (
        <>
          <AppRefreshHandler />
          <TopConnectionComponent />
          <MetamaskPopup open={isWaitingConfirmation} onClose={handleMetaMaskPopupClose} />

          {isLiteVersion ? (
            <LiteVersion isLiteVersion={isLiteVersion} handleSetVersion={handleSetVersion} />
          ) : (
            <>
              <Header  isLiteVersion={isLiteVersion} setLiteVersion={handleSetVersion} />
              <div className="components-container text-white items-stretch border-b border-gray-650 bg-black">
                <Assets />

                <div className="flex-1 h-full tw-chart-container">
                  <ChartResolutionSelector />
                  <TVChartContainer />
                </div>



                  {windowWidth > 800 && (
                    <>

                     <div className="flex flex-col justify-center items-center tradesorder-container h-full">
                      <div className="trades-desktop">
                        <Trades />
                      </div>
                      <OrderBook />
                      </div>

                       <div className="w-60 orderbox-container container-scroll">
                        <OrderBox />
                      </div>
                    </>
                  )}

                {windowWidth <= 800 && (
                  <>
                  <div className="w-60 orderbox-container container-scroll">
                        <OrderBox />
                      </div>
                  <div className="trades-mobile">
                    <Trades />
                    <OrderBook />
                  </div>
                  </>
                )}

                <div className="break-column-1050" />
              </div>

              <PositionsContainer />
            </>
          )}
        </>
      )}
      <ToastContainer
        position="bottom-right"
        theme="dark"
        hideProgressBar={true}
        autoClose={1000}
      />
    </div>
  );
};

export default App;
