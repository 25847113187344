// DepositERC20.ts

import React, { useState, useEffect } from "react";
import BN from 'bn.js';
import { Wallet } from "../interfaces/wallet.interface";
import { MarginCurrency } from "../interfaces/marginCurrency.interface";
import { Fund } from "../interfaces/fund.interface";
import Web3 from 'web3';
import { eventCloud } from './../EventCloud';


import erc20ABIData from './abi/ERC20.json';
const erc20ABI = erc20ABIData.abi;

import bridgeAbiData from './abi/ConsensusBridgeDChain.json';
const bridgeAbi = bridgeAbiData.abi;

import { getEthProvider } from '../helpers/web3Provider';
import { switchNetwork } from '../helpers/switchNetwork';
import { requestAccountAccess } from '../helpers/requestAccountAccess';
import { formatNotificationMessage } from "../helpers/notificationHelpers";

import { toast } from 'react-toastify';

interface DepositsParams {
    wallet: Wallet;
    marginCurrency: MarginCurrency;
    amount: string;
    recommendedGasPrice: number;
    setApprovalPending: (val: boolean) => void,
    setDepositPending: (val: boolean) => void,
    setWaitingConfirmation: (val: boolean) => void,
    approveGasLimit: number,
    depositGasLimit: number,
    onClose: () => void,
    setPendingTxHash: (hash: string) => void;
}

export const handleDepositERC20 = async ({
    wallet,
    marginCurrency,
    amount,
    recommendedGasPrice,    
    setApprovalPending,
    setDepositPending,
    approveGasLimit,
    depositGasLimit,
    onClose,
    setWaitingConfirmation,
    setPendingTxHash,
}: DepositsParams) => {  
    console.log(`[handleDepositERC20] amount=${amount} recommendedGasPrice=${recommendedGasPrice}`);
    if (!wallet) throw new Error("Wallet is not provided");
    
    let web3;    
    if (!wallet.isMetaMask) {
        web3 = new Web3(getEthProvider(marginCurrency.network));  
    } else {
        web3 = new Web3(window.ethereum); 
        
        var success = await switchNetwork(marginCurrency.network);
        if (!success) {
            return;
        }

        success = await requestAccountAccess();
        if (!success) {
            return;
        }
    }
    
    const userAddress = wallet.address;
    const privateKey = wallet.privateKey;

    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    const daiContractAddress = ENVIRONMENT === "dev"
      ? process.env.REACT_APP_DEV_DAI_ADDRESS
      : marginCurrency.token_contract_address;

    let contract;

    // Convert the amount to Wei
    const amountInWei = new BN(web3.utils.toWei(amount, 'ether'));

    // Adjust for token decimals
    let amountAdjusted = amountInWei;
    if (marginCurrency.token_decimals !== 18) {
        const divisor = new BN(10).pow(new BN(18 - marginCurrency.token_decimals));
        amountAdjusted = amountInWei.div(divisor);
    }
    const amountAdjustedString = amountAdjusted.toString();

    const recommendedMaxFeePerGas = web3.utils.toWei(recommendedGasPrice.toString(), 'gwei');

    if (!wallet.isMetaMask) {
        if (!privateKey) throw new Error("Private key missing");         

        const account = web3.eth.accounts.privateKeyToAccount(privateKey);
        web3.eth.accounts.wallet.add(account);
        web3.eth.defaultAccount = account.address;
    } 

    let approvalSucceded = false;

    let toastId = toast.loading("INITIATING DEPOSIT");
    contract = new web3.eth.Contract(erc20ABI, daiContractAddress);
    const currentAllowance = await (contract.methods.allowance as any)(userAddress, marginCurrency.bridge_address).call();
    if (new BN(currentAllowance).gte(amountAdjusted)) {
        console.log("Amount is already approved for spending", currentAllowance);
        approvalSucceded = true;
        //toast.update(toastId, { render: "ALLOWANCE SUFFICIENT. AWAITING DEPOSIT TX", isLoading: true });
    } else {
        console.log("Need to approve spending amount", currentAllowance);
        toast.update(toastId, { render: "AWAITING APPROVAL TX", isLoading: true });
        try {
            
            if (wallet.isMetaMask) {
                setWaitingConfirmation(true);
                eventCloud.emit("waitingMetaMaskConfirmation", {}); 
            } else {
                setApprovalPending(true);
            }

            await (contract.methods.approve as any)(marginCurrency.bridge_address, amountAdjustedString).send({ 
                from: userAddress, 
                gas: approveGasLimit.toString(), 
                gasPrice: recommendedMaxFeePerGas,
            }).on('transactionHash', (hash: any) => {
                console.log("[handleDepositERC20] transactionHash=", hash)
                // User has confirmed the transaction in MetaMask
                setPendingTxHash(hash);
                setWaitingConfirmation(false);
                setApprovalPending(true);
                eventCloud.emit("unWaitMetaMask", {}); 
                toast.update(toastId, { render: "APPROVAL REQUEST SENT, Awaiting TX confirmation", isLoading: true });
            })
            .on('receipt', (receipt: any) => {
                console.log("[handleDepositERC20] receipt=", receipt)
                // Transaction was confirmed on the blockchain
                setPendingTxHash("");
                setApprovalPending(false);
                eventCloud.emit("unWaitMetaMask", {}); 
                toast.update(toastId, { render: "APPROVAL CONFIRMED, Awaiting deposit TX", isLoading: true });
                approvalSucceded = true;
            })
            .on('error', (error: any, receipt: any) => {
                console.log("[handleDepositERC20] error=", error)
                // An error occurred, handle it
                setWaitingConfirmation(false);
                setApprovalPending(false);
                eventCloud.emit("unWaitMetaMask", {}); 
                toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
                // Optionally, handle the error or the transaction receipt if available
                if (error.code === 4001) {
                    // User rejected the transaction
                    console.log('Transaction rejected by the user.');
                } else {
                    // Other errors
                    console.error('An error occurred', error);
                }
            })
            .catch((error: any) => {
                // This will catch any errors not caught by the .on('error') handler
                console.error("Transaction rejected by user: ", error.message);
                // Perform any additional error handling here
                setWaitingConfirmation(false);
                setDepositPending(false);
                eventCloud.emit("unWaitMetaMask", {}); 

                toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
            });
        } catch (error: any) {
            setWaitingConfirmation(false);
            setApprovalPending(false);
            eventCloud.emit("unWaitMetaMask", {}); 
            toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
        }
    }
    

    if (approvalSucceded) {
        try {
            contract = new web3.eth.Contract(bridgeAbi, marginCurrency.bridge_address);

            if (wallet.isMetaMask) {
                setWaitingConfirmation(true);
                eventCloud.emit("waitingMetaMaskConfirmation", {}); 
            } else {
                setDepositPending(true);
            }
            toast.update(toastId, { render: "AWAITING DEPOSIT TX", isLoading: true });
            // Deposit the tokens
            const depositTokenMethod: any = contract.methods.depositToken;
            const prom = new Promise((resolve, reject) => { 
                const unsubscribe = eventCloud.on("newFund", (data: Fund) => {
                    if (data.type == "deposit") {
                        console.log("[handleDepositETH] depositReceived=", data);
                        //toast.update(toastId, { render: "Deposit received.", type: "success", isLoading: false, autoClose: 5000 });
                        toast.dismiss(toastId);
                        resolve(data);
                        unsubscribe();
                    }
                });

                depositTokenMethod(daiContractAddress, amountAdjustedString).send({ 
                    from: userAddress, 
                    gas: depositGasLimit.toString(),
                    gasPrice: recommendedMaxFeePerGas, 
                }).on('transactionHash', (hash: any) => {
                    console.log("[handleDepositERC20] transactionHash=", hash)
                    // User has confirmed the transaction in MetaMask
                    setPendingTxHash(hash);
                    setWaitingConfirmation(false);
                    setDepositPending(true);
                    eventCloud.emit("unWaitMetaMask", {}); 
                    toast.update(toastId, { render: "DEPOSIT TX SENT, Awaiting TX confirmation", isLoading: true });
                })
                .on('receipt', (receipt: any) => {
                    console.log("[handleDepositERC20] receipt=", receipt)
                    // Transaction was confirmed on the blockchain
                    setPendingTxHash("");
                    onClose();
                    setDepositPending(false);
                    toast.update(toastId, { render: "DEPOSIT TX CONFIRMED, Awaiting arrival", isLoading: true });
                })
                .on('error', (error: any, receipt: any) => {
                    console.log("[handleDepositERC20] error=", error)
                    // An error occurred, handle it
                    setWaitingConfirmation(false);
                    setDepositPending(false);
                    eventCloud.emit("unWaitMetaMask", {}); 
                    // Optionally, handle the error or the transaction receipt if available
                    if (error.code === 4001) {
                        // User rejected the transaction
                        console.log('Transaction rejected by the user.');
                    } else {
                        // Other errors
                        console.error('An error occurred', error);
                        toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
                        eventCloud.notify(
                            formatNotificationMessage({
                            title: "METAMASK ERROR",
                                message: [
                                    {
                                        text: error.message,
                                        bold: false,
                                    },
                                ],
                            }),
                            "error",
                            "10000"
                        );
                    }

                    unsubscribe();
                }).catch((error: any) => {
                    // This will catch any errors not caught by the .on('error') handler
                    console.error("Transaction rejected by user: ", error.message);
                    // Perform any additional error handling here
                    setWaitingConfirmation(false);
                    setDepositPending(false);
                    eventCloud.emit("unWaitMetaMask", {}); 

                    toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
                });                
            });
           
        } catch (error: any) {
            console.error('An error occurred', error);
            eventCloud.notify(
                formatNotificationMessage({
                title: "METAMASK ERROR",
                    message: [
                        {
                            text: error.message,
                            bold: false,
                        },
                    ],
                }),
                "error",
                "10000"
            );
            onClose();
            setWaitingConfirmation(false);
            setDepositPending(false);
            toast.update(toastId, { render: `Error: ${error.message}`, type: "error", isLoading: false, autoClose: 5000 });
        }        
    }
    
};

export default handleDepositERC20;





