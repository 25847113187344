import { Position } from "../interfaces/position.interface";
import { MarginCurrency } from "../interfaces/marginCurrency.interface";

export const formatPrice = (price: bigint | number, decimals: number): string => {
  return (Number(price) / 1e8).toFixed(decimals);
};

export const formatPriceNum = (price: number, decimals: number): string => {
  return Intl.NumberFormat("en-US").format(+price.toFixed(decimals));
};

export const formatPriceLocale = (price: number, decimals: number): string => {
  if (!decimals) return "0";
  //console.log("[formatPriceLocale] price=", price, decimals)
  const userLocale = navigator.language;
  return new Intl.NumberFormat(userLocale, {
    style: "decimal",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(price / 1e8);
};

export const formatFundingRate = (fr: number, usr_mult: number): string => {
  return ((fr / 1e16) * usr_mult).toFixed(6);
};

export const formatNumLocale = (price: number, decimals: number): string => {
  return new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(price);
};

export const formatAmount = (amount: number, amount_dec: number): string => {
  return (Number(amount) / 1e8).toFixed(amount_dec);
};

export const calcUPnL = (
  position: Position,
  marginCurrencies: Record<string, MarginCurrency>
): number => {
  //console.log("[calcUPnL] ", side, entryPrice, lastPrice, amount)
  var upnl = 0;

  if (position.side) {
    upnl =
      (Number(position.last_price) - Number(position.entry_price)) *
      Number(position.size);
  } else {
    upnl =
      (Number(position.entry_price) - Number(position.last_price)) *
      Number(position.size);
  }

  return (
    upnl / 1e8 / Number(marginCurrencies[position.base_token]?.mark_price)
    //- Number(position.ur_funding_cost)
  );
};

export const calcRPnL = (position: Position): number => {
  var rpnl =
    Number(position.profit) -
    Number(position.loss) -
    Number(position.funding_cost) -
    Number(position.ur_funding_cost);

  return rpnl;
};


export const calcLeverage = (
  position: Position,
  marginCurrencies: Record<string, MarginCurrency>,
  cross_profit: number
): string => {
  return Math.abs(
    Number(position.value) /
      (Number(position.margin) +
        // Number(position.ur_funding_cost) +
        Number(position.profit) -
        Number(position.loss) +
        (!position.is_cross
          ? Number(calcUPnL(position, marginCurrencies)) -
            Number(position.funding_cost)
          : cross_profit)) /
      Number(marginCurrencies[position.base_token]?.mark_price)
  ).toFixed(2);
};
