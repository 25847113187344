import { FieldProps } from "../types/field";

export const getFieldIds = ({ id, name }: Pick<FieldProps, "id" | "name">) => {
  const computedId = id || name;

  const errorId = `${computedId}-error`;

  const optionalId = `${computedId}-optional`;

  return {
    errorId,
    computedId,
    optionalId,
  };
};
