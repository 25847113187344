import React from "react";

export const Blink1 = () => {
  return (
    <svg
      width="459"
      height="515"
      viewBox="0 0 459 515"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="blinkImage1"
    >
      <g clipPath="url(#clip0_103_622)">
        <path
          className="blinking"
          d="M65.4532 4.46005L60.9932 0L56.5331 4.46005L60.9932 8.9201L65.4532 4.46005Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.522 4.46005L117.062 0L112.601 4.46005L117.062 8.9201L121.522 4.46005Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.522 4.46005L117.062 0L112.601 4.46005L117.062 8.9201L121.522 4.46005Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M233.652 4.46005L229.192 0L224.732 4.46005L229.192 8.9201L233.652 4.46005Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M401.854 4.46005L397.394 0L392.934 4.46005L397.394 8.9201L401.854 4.46005Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M457.92 4.46005L453.46 0L449 4.46005L453.46 8.9201L457.92 4.46005Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.7208 60.5267L61.2607 56.0667L56.8007 60.5267L61.2607 64.9867L65.7208 60.5267Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M290.721 60.5267L286.261 56.0667L281.801 60.5267L286.261 64.9867L290.721 60.5267Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M121.785 60.5265L117.325 56.0664L112.865 60.5265L117.325 64.9865L121.785 60.5265Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M346.785 60.5265L342.325 56.0664L337.865 60.5265L342.325 64.9865L346.785 60.5265Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M177.854 60.5265L173.394 56.0664L168.934 60.5265L173.394 64.9865L177.854 60.5265Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M402.854 60.5265L398.394 56.0664L393.934 60.5265L398.394 64.9865L402.854 60.5265Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M8.92001 60.5267L4.45996 56.0667L-8.86279e-05 60.5267L4.45996 64.9867L8.92001 60.5267Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M233.92 60.5267L229.46 56.0667L225 60.5267L229.46 64.9867L233.92 60.5267Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.4532 116.593L60.9932 112.133L56.5331 116.593L60.9932 121.053L65.4532 116.593Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.586 116.593L173.126 112.133L168.666 116.593L173.126 121.053L177.586 116.593Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M233.652 116.593L229.192 112.133L224.732 116.593L229.192 121.053L233.652 116.593Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M289.721 116.593L285.261 112.133L280.801 116.593L285.261 121.053L289.721 116.593Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M345.785 116.593L341.325 112.133L336.865 116.593L341.325 121.053L345.785 116.593Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M401.854 116.593L397.394 112.133L392.934 116.593L397.394 121.053L401.854 116.593Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M8.92001 116.593L4.45996 112.133L-8.86279e-05 116.593L4.45996 121.053L8.92001 116.593Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M457.92 116.593L453.46 112.133L449 116.593L453.46 121.053L457.92 116.593Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 172.659L60.7666 168.199L56.3066 172.659L60.7666 177.119L65.2267 172.659Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 172.659L60.7666 168.199L56.3066 172.659L60.7666 177.119L65.2267 172.659Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 172.659L116.835 168.199L112.375 172.659L116.835 177.119L121.295 172.659Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.359 172.659L172.899 168.199L168.439 172.659L172.899 177.119L177.359 172.659Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M233.426 172.659L228.966 168.199L224.506 172.659L228.966 177.119L233.426 172.659Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M289.494 172.659L285.034 168.199L280.574 172.659L285.034 177.119L289.494 172.659Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M345.559 172.659L341.099 168.199L336.639 172.659L341.099 177.119L345.559 172.659Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M401.627 172.659L397.167 168.199L392.707 172.659L397.167 177.119L401.627 172.659Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M457.693 172.659L453.233 168.199L448.773 172.659L453.233 177.119L457.693 172.659Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 228.725L60.7666 224.265L56.3066 228.725L60.7666 233.185L65.2267 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 509.725L60.7666 505.265L56.3066 509.725L60.7666 514.185L65.2267 509.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 228.725L116.835 224.265L112.375 228.725L116.835 233.185L121.295 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 509.725L116.835 505.265L112.375 509.725L116.835 514.185L121.295 509.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 509.725L116.835 505.265L112.375 509.725L116.835 514.185L121.295 509.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.359 228.725L172.899 224.265L168.439 228.725L172.899 233.185L177.359 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.359 509.725L172.899 505.265L168.439 509.725L172.899 514.185L177.359 509.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M233.426 228.725L228.966 224.265L224.506 228.725L228.966 233.185L233.426 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M289.494 228.725L285.034 224.265L280.574 228.725L285.034 233.185L289.494 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M345.559 228.725L341.099 224.265L336.639 228.725L341.099 233.185L345.559 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M401.627 228.725L397.167 224.265L392.707 228.725L397.167 233.185L401.627 228.725Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 284.792L60.7666 280.332L56.3066 284.792L60.7666 289.252L65.2267 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 284.792L116.835 280.332L112.375 284.792L116.835 289.252L121.295 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.359 284.792L172.899 280.332L168.439 284.792L172.899 289.252L177.359 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M233.426 284.792L228.966 280.332L224.506 284.792L228.966 289.252L233.426 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M289.494 284.792L285.034 280.332L280.574 284.792L285.034 289.252L289.494 284.792Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M345.559 284.792L341.099 280.332L336.639 284.792L341.099 289.252L345.559 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M8.92001 284.792L4.45996 280.332L-8.86279e-05 284.792L4.45996 289.252L8.92001 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M8.92001 284.792L4.45996 280.332L-8.86279e-05 284.792L4.45996 289.252L8.92001 284.792Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 340.858L60.7666 336.398L56.3066 340.858L60.7666 345.318L65.2267 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 340.858L116.835 336.398L112.375 340.858L116.835 345.318L121.295 340.858Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M177.359 340.858L172.899 336.398L168.439 340.858L172.899 345.318L177.359 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M233.426 340.858L228.966 336.398L224.506 340.858L228.966 345.318L233.426 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M289.494 340.858L285.034 336.398L280.574 340.858L285.034 345.318L289.494 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M345.559 340.858L341.099 336.398L336.639 340.858L341.099 345.318L345.559 340.858Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M401.627 340.858L397.167 336.398L392.707 340.858L397.167 345.318L401.627 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M8.92001 340.858L4.45996 336.398L-8.86279e-05 340.858L4.45996 345.318L8.92001 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M457.693 340.858L453.233 336.398L448.773 340.858L453.233 345.318L457.693 340.858Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 396.924L60.7666 392.464L56.3066 396.924L60.7666 401.384L65.2267 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 396.924L116.835 392.464L112.375 396.924L116.835 401.384L121.295 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.359 396.924L172.899 392.464L168.439 396.924L172.899 401.384L177.359 396.924Z"
          fill="#FFAC5A"
        />
        <path
          className="blinking"
          d="M233.426 396.924L228.966 392.464L224.506 396.924L228.966 401.384L233.426 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M289.494 396.924L285.034 392.464L280.574 396.924L285.034 401.384L289.494 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M345.559 396.924L341.099 392.464L336.639 396.924L341.099 401.384L345.559 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M401.627 396.924L397.167 392.464L392.707 396.924L397.167 401.384L401.627 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M8.92001 396.924L4.45996 392.464L-8.86279e-05 396.924L4.45996 401.384L8.92001 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M457.693 396.924L453.233 392.464L448.773 396.924L453.233 401.384L457.693 396.924Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M65.2267 452.991L60.7666 448.531L56.3066 452.991L60.7666 457.451L65.2267 452.991Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M121.295 452.991L116.835 448.531L112.375 452.991L116.835 457.451L121.295 452.991Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M177.359 452.991L172.899 448.531L168.439 452.991L172.899 457.451L177.359 452.991Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M289.494 452.991L285.034 448.531L280.574 452.991L285.034 457.451L289.494 452.991Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M345.559 452.991L341.099 448.531L336.639 452.991L341.099 457.451L345.559 452.991Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
        <path
          className="blinking"
          d="M345.559 452.991L341.099 448.531L336.639 452.991L341.099 457.451L345.559 452.991Z"
          stroke="#FFAC5A"
          strokeOpacity="0.27"
          strokeWidth="0.630746"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_622">
          <rect width="458.92" height="514.185" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
