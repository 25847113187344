// Orders.tsx

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { eventCloud } from "./EventCloud";
import { Button } from "./components/Button/Button";
import { Order } from "./interfaces/order.interface";
import { MarginCurrency } from "./interfaces/marginCurrency.interface";

import CancelOrderDialog from "./dialogs/CancelOrderDialog";
import "./css/Orders.css";
import { Modal } from "./components/Modal/Modal";

// const generateMockOrder = (): Order => {
//   return {
//     hash: 'uuidv4()',
//     user_address: 'uuidv4()',
//     base_token: '0x0000000000000000000000000000000000000003',
//     asset: 'ETH',
//     amount: '1000000000000000000', // Example amount in wei for 1 ETH
//     price: '2000', // Example price in USD
//     side: Math.random() > 0.5,
//     nonce: Math.floor(Math.random() * 1000),
//     leverage: '3',
//     maintenance_margin: 0.5,
//     closing_order: false,
//     v: 27,
//     r: 'uuidv4()',
//     s: 'uuidv4()',
//     remaining_amount: '500000000000000000', // Example remaining amount in wei for 0.5 ETH
//     closed: false,
//     stop: false,
//     stop_price: '1900', // Example stop price in USD
//     stopped: false,
//     is_market: Math.random() > 0.5,
//     decimals: 18,
//     amount_dec: 18,
//     margin_currency_symbol: 'USD',
//     close_reason: 'filled',
//     created_at: new Date().toISOString()
//   };
// };
//
// const generateOrders = (count: number): Order[] => {
//   return Array.from({ length: count }, generateMockOrder);
// };
//
// const oo = generateOrders(20);

const Orders = ({ isLite }: { isLite?: boolean }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [selectedOrderHash, setSelectedOrderHash] = useState("");
  const [marginCurrencies, setMarginCurrencies] = useState<
    Record<string, MarginCurrency>
  >({});

  useEffect(() => {
    // Subscribe to assetsStore
    const subscription = eventCloud.ordersStore.subscribe((orders) => {
      if (!orders || orders.length === 0) {
        setOrders([]); // Return early if newAssets is empty
        return;
      }
      //console.log("Subscribe new orders ", orders);
      setOrders(orders);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (eventCloud.marginCurrenciesStore) {
      const currencySubscription = eventCloud.marginCurrenciesStore.subscribe(
        (currencies) => {
          //console.log("[Positions] marginCurrenciesStore ", currencies);
          if (currencies) {
            setMarginCurrencies(currencies);
          }
        }
      );

      return () => {
        currencySubscription.unsubscribe();
      };
    }
  }, []);



  useEffect(() => {
  	const handleCancelOrder = (order: Order) => {
	  handleCloseOrder(order.hash);
	};

    const unsubscribeCancelOrder = eventCloud.on("cancelOrder", handleCancelOrder);

    return () => {
      unsubscribeCancelOrder();
    };
  }, []);

  const handleCloseOrder = (orderHash: string) => {
    setSelectedOrderHash(orderHash);
    setIsCancelDialogOpen(true);
  };


  const handleCancelClose = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancellation = () => {
    console.log("Confirmed cancellation of order", selectedOrderHash);
    setIsCancelDialogOpen(false);
    // Add logic here to handle the actual order cancellation
  };

  return (
    <div className="text-xs positions-block-wrapper">
      <h2
        className={classNames(
          "px-2 py-2 bg-black bg-opacity-30 border-gray-650 font-medium text-xs table-headline",
          { "!hidden": isLite }
        )}
      >
        Orders
        <span className="text-yellow-550"> [{orders.length}]</span>
      </h2>
      <div className={classNames("order-table-wrapper container-scroll text-gray-150", { isLite: isLite })}>
        <table
          style={{ width: "100%", borderCollapse: "collapse", minWidth: '1200px' }}
          className={classNames("order-table", { isLite: isLite })}
        >
          <thead>
            <tr>
              <th className="text-left">Hash</th>
              <th className="text-left">Time</th>
              <th className="text-left">Asset</th>
              <th className="text-right">Amount</th>
              <th className="text-left">Side</th>
              <th className="text-right">Price</th>
              <th className="text-right">Filled</th>
              <th className="text-right">Remaining</th>
              <th className="text-right">Margin</th>
              <th className="text-right">Leverage</th>
              <th className="text-right">Trigger Price</th>
              <th className="text-right">Triggered</th>
              <th className="text-right">Reduce Only</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td className="text-left">{order.hash.slice(0, 10)}</td>
                <td className="text-left">
                  {formatTimestamp(order.created_at)}
                </td>
                <td className="text-left">{order.asset.replace(/usd/g, "").toUpperCase()}</td>
                <td className="text-right">
                  {formatNumLocale(Number(order.amount)/1e8, order.amount_dec)}
                </td>
                <td
                  className={classNames("text-left", [
                    order.side ? "text-green-550" : "text-red-550",
                  ])}
                >
                  {/*{order.side ? "Buy" : "Sell"}*/}

                  <div
                    className={classNames(
                      "inline-block text-xs px-1 bg-opacity-20 ml-1",
                      [
                        order.side
                          ? "text-green-550 bg-green-550"
                          : "text-red-550 bg-red-550",
                      ]
                    )}
                  >
                    {order.side ? "BUY" : "SELL"}
                  </div>
                </td>
                <td className="text-right">
                	{formatPrice(Number(order.price), order.decimals)}
                </td>
                <td className="text-right">
                  {formatNumLocale(
                    (Number(order.amount) - Number(order.remaining_amount))/1e8,
                    order.amount_dec
                  )}
                </td>
                <td className="text-right">
                  {formatNumLocale(
                    Number(order.remaining_amount)/1e8,
                    order.amount_dec
                  )}
                </td>
                <td className="text-right">
                  	{ order.closing_order
                  		? "--"
                  		: order.leverage == "0"
                  			? "[CROSS]"
                  			: formatMargin(
			                    Number(order.amount),
			                    Number(order.price),
			                    Number(order.leverage),
			                    Number(marginCurrencies[order.base_token]?.mark_price),
			                    Number(marginCurrencies[order.base_token]?.decimals)
			                  )
                  	}
                  	{" "}{marginCurrencies[order.base_token]?.symbol}{" "}
                  	[{eventCloud.findMarginCurrency(order.base_token)?.network_slug}]
                </td>
                <td className="text-right">
                  {order.closing_order ? "--" : formatLeverage(Number(order.leverage))+"x"}
                </td>
                <td className="text-right">
                  {order.stop_price == "0"
                    ? "--"
                    : formatPrice(Number(order.stop_price), order.decimals)}
                </td>
                <td className="text-right">
                  {order.stop_price == "0"
                    ? "--"
                    : order.stopped
                    ? "Yes"
                    : "No"}
                </td>
                <td className="text-right">
                  {order.closing_order ? "Yes" : "No"}
                </td>
                <td className="text-right">
                  <Button
                    type="button"
                    variant="gray"
                    size="small"
                    onClick={() => handleCloseOrder(order.hash)}
                  >
                    Close
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {orders.length === 0 && (
          <div className="py-3 text-center text-xs text-gray-450 h-full pb-8 flex justify-center items-center" style={{ height: '80%' }}>
            No data
          </div>
        )}
      </div>

      {/* Cancel Order Dialog */}
      <Modal
        visible={isCancelDialogOpen}
        onClose={handleCancelClose}
        freeToClose
      >
        <CancelOrderDialog
          open={isCancelDialogOpen}
          onClose={handleCancelClose}
          orderHash={selectedOrderHash}
          onConfirm={handleConfirmCancellation}
        />
      </Modal>
    </div>
  );
};

const formatMargin = (
  amount: number,
  price: number,
  leverage: number,
  mark_price: number,
  decimals: number
): string => {
  return ((amount * price) / leverage / mark_price / 1e8).toFixed(decimals);
};

const formatPrice = (price: number, decimals: number): string => {

  return (Number(price) / 1e8).toFixed(decimals);
};

const formatAmount = (amount: number, amount_dec: number): string => {
  return (Number(amount) / 1e8).toFixed(amount_dec);
};

const formatLeverage = (leverage: number): string => {
  return (Number(leverage) / 1e8).toFixed(0);
};

const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};


const formatNumLocale = (price: number, decimals: number): string => {
    return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(price);
};

export default Orders;
