import React, { useState, useEffect, useReducer } from "react";
import { Button } from "../Button/Button";
import { eventCloud } from "../../EventCloud";
import { assetsReducer } from "../../Assets";
import { Params } from "../../interfaces/params.interface";
import { Asset } from "../../interfaces/asset.interface";
import { ReactComponent as IconLogo } from "../../assets/icons/svg/logo.svg";
import { Blink1 } from "./blink1";
import { Blink2 } from "./blink2";
import classNames from "classnames";
import { formatPriceLocale } from "../../utils/price";

interface HomePageProps {
  open: boolean;
  onClose: () => void;
  windowWidth: number;
  setLiteVersion: (isLite: boolean) => void;
}

export const Homepage: React.FC<HomePageProps> = ({
  open,
  onClose,
  windowWidth,
  setLiteVersion,
}) => {
  const [volume, setVolume] = useState(0);
  const [oi, setOi] = useState(0);
  const [dailyTrades, setDailyTrades] = useState(0);
  const [params, setParams] = useState<Params | null>(null);
  const [currentExpressionIndex, setCurrentExpressionIndex] = useState(0);
  const [assets, dispatch] = useReducer(assetsReducer, []);

  // List of expressions to rotate
  const expressions = [
    "No Kyc",
    "No Liquidations",
    "No Gas Fees",
    "cross margin",
    "demo trading",
    "Open Source",
    "self-custody",
  ];

  useEffect(() => {
    const randomizeBlinking = () => {
      const elements = document.querySelectorAll(".blinking");

      elements.forEach((el, i) => {
        if (!(i % 2)) {
          const element = el as HTMLElement;
          const delay = Math.random() * 10;
          element.style.animation = `blink 2s ease ${delay}s infinite alternate`;
        }
        if (!(i % 7)) {
          const element = el as HTMLElement;
          element.style.visibility = `hidden`;
        }
      });
    };

    randomizeBlinking();
  }, []);

  // Cycle through expressions every 1 second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentExpressionIndex(
        (prevIndex) => (prevIndex + 1) % expressions.length
      );
    }, 1000); // 1 second interval

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up on component unmount
    };
  }, [open]);

  useEffect(() => {
    const subscription = eventCloud.assetsStore.subscribe((newAssets) => {
      if (newAssets.length === 0) return;

      // Calculate the cumulative volume
      const cumulativeVolume = newAssets.reduce((totalVolume, asset) => {
        return totalVolume + asset.volume;
      }, 0);

      // Calculate the cumulative open interest
      const cumulativeOi = newAssets.reduce((totalOi, asset) => {
        return totalOi + asset.open_interest;
      }, 0);

      // Calculate the cumulative trades
      const cumulativeTrades = newAssets.reduce((totalTrades, asset) => {
        return totalTrades + asset.daily_trades;
      }, 0);

      setVolume(cumulativeVolume);
      setOi(cumulativeOi);
      setDailyTrades(cumulativeTrades);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = eventCloud.paramsStore.subscribe((newParams) => {
      setParams(newParams);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLiteVersion = () => {
    setLiteVersion(true);
    onClose();
  };

  const handleProVersion = () => {
    setLiteVersion(false);
    onClose();
  };

  useEffect(() => {
    const subscription = eventCloud.assetsStore.subscribe((newAssets) => {
      if (newAssets.length === 0) return;

      dispatch({ type: "UPDATE_ASSETS", payload: newAssets });
      //console.log("[Assets] Assets updated: ", newAssets);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (assets.length === 0) return;
    const handleNewTrade = (trade: any) => {
      if (assets.length === 0) return;

      const updatedAssets = assets.map((asset: Asset) => {
        if (asset.symbol.toUpperCase() === trade.Asset.toUpperCase()) {
          const newVolume = asset.volume + (trade.Price * trade.Amount) / 1e16;
          let change = 0;
          if (asset.open !== 0) {
            change = ((trade.Price / 1e8 - asset.open) / asset.open) * 100;
          }

          const updatedAsset = {
            ...asset,
            last_price: trade.Price,
            volume: newVolume,
            change: change,
          };
          dispatch({ type: "UPDATE_ASSET", payload: updatedAsset });
        }
        return asset;
      });
    };

    const unsubscribeNewTrade = eventCloud.on("newTrade", handleNewTrade);

    return () => {
      unsubscribeNewTrade();
    };
  }, [assets]);

  if (!open) return null;

  return (
    <>
      {/* Header Top Bar */}
      <header style={headerStyle} className="border-b border-gray-650">
        <div className="flex">
          <div className="px-1 ml-2 sm:ml-4 w-20 sm:px-4 sm:w-26 h-12 flex justify-center items-center header_logo">
            <IconLogo className="w-16 sm:w-20" />
          </div>
          <nav style={navLinksStyle} className="pt-4 ml-4">
            <a
              href="https://docs.dmex.app"
              target="_blank"
              style={navLinkStyle}
            >
              Docs
            </a>
            <a
              href="https://dmexhelp.freshdesk.com/support/home"
              target="_blank"
              style={navLinkStyle}
            >
              Support
            </a>
            <a
              href="https://t.me/dmex_official"
              target="_blank"
              style={navLinkStyle}
            >
              Telegram
            </a>
          </nav>
        </div>
        <Button
          className="ml-6 mr-4 text-right"
          onClick={onClose}
          variant="yellow"
          size="smallx"
        >
          TRADE
        </Button>
      </header>

      <div className="overflow-x-hidden">
        {/* Modal Content */}
        <div
          className="modal-content pb-20"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "#000", // Black background
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Vertically align content in the center
            zIndex: 1000,
            overflowY: "auto",
            padding: "70px 20px 20px 20px", // Adding padding to avoid header overlay (adjust if needed)
          }}
        >
          <div
            className="hero-content relative z-10"
            style={{
              maxWidth: "1200px",
              minHeight: '500px',
              //padding: "20px",
              margin: "auto", // Centering the content both vertically and horizontally
            }}
          >
            <h1
              className="text-white hero-title"
              style={{
                fontSize: windowWidth < 800 ? "28px" : "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              Experience The most powerful <br />
              <span className="text-yellow-550">margin trading</span> protocol
              in <span className="text-yellow-550">DeFi</span>
            </h1>
            <p
              className="text-white hero-description"
              style={{
                fontSize: windowWidth < 800 ? "12px" : "16px",
                marginTop: "20px",
                maxWidth: "650px",
              }}
            >
              <span className="text-yellow-550">DMEX</span> is the leading
              decentralized platform for derivatives trading, providing top-tier
              execution with vast liquidity and complete privacy.
            </p>

            <div
              className="mt-4"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* Rotating text next to the button */}
              <div
                style={{
                  //marginLeft: "20px", // space between button and text
                  height: "50px", // Set a fixed height for smooth rotation
                  overflow: "hidden", // Hide the overflow to simulate rotation
                  display: "flex",
                  flexDirection: "column",
                  //justifyContent: "center",
                  color: "white",
                }}
              >
                {/* Rotating text container */}
                <div
                  className="mt-1 text-yellow-550 text-3xl sm:text-4xl"
                  style={{
                    transition: "transform 0.5s ease-in-out", // Smooth transition effect
                    transform: `translateY(-${currentExpressionIndex * 50}px)`, // Moves text vertically
                  }}
                >
                  {expressions.map((expression, index) => (
                    <div
                      key={index}
                      style={{
                        height: "50px", // Ensure each expression takes up full height for smooth scrolling
                        textAlign: "left",
                        whiteSpace: "nowrap", // Prevent line breaks
                      }}
                    >
                      {expression}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Button
              onClick={handleLiteVersion}
              variant="green"
              size="large"
              style={{ padding: "15px 30px", marginTop: "20px" }}
            >
              LITE VERSION
            </Button>
            <Button
              onClick={handleProVersion}
              variant="red"
              size="large"
              className="ml-2"
              style={{ padding: "15px 30px", marginTop: "20px" }}
            >
              PRO VERSION
            </Button>

            <div
              className="stats-section"
              style={{ display: "flex", marginTop: "20px", maxWidth: "600px" }}
            >
              <div
                className="stat border-gray-650 border p-4 bg-black relative z-10"
                style={{ textAlign: "left" }}
              >
                <p
                  style={{
                    color: "#aaa",
                    fontSize: windowWidth < 800 ? "10px" : "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  24h Volume
                </p>
                <h3
                  style={{
                    fontSize: windowWidth < 800 ? "14px" : "22px",
                    color: "#fff",
                  }}
                >
                  {formatVolume(volume)}
                </h3>
              </div>
              {/*<div className="stat" style={{ textAlign: "left" }}>
              <p style={{ color: "#aaa", fontSize: "16px" }}>24h Trades</p>
              <h3 style={{ fontSize: "24px", margin: "10px 0", color: "#fff" }}>{formatTrades(dailyTrades)}</h3>
            </div>*/}
              <div
                className="stat border-gray-650 border p-4 ml-4 bg-black relative z-10"
                style={{ textAlign: "left" }}
              >
                <p
                  style={{
                    color: "#aaa",
                    fontSize: windowWidth < 800 ? "10px" : "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Open Interest
                </p>
                <h3
                  style={{
                    fontSize: windowWidth < 800 ? "14px" : "22px",
                    color: "#fff",
                  }}
                >
                  {formatOi(oi)}
                </h3>
              </div>
              <div
                className="stat border-gray-650 border p-4 ml-4 bg-black relative z-10"
                style={{ textAlign: "left" }}
              >
                <p
                  style={{
                    color: "#aaa",
                    fontSize: windowWidth < 800 ? "10px" : "14px",
                  }}
                >
                  Traders
                </p>
                <h3
                  style={{
                    fontSize: windowWidth < 800 ? "14px" : "22px",
                    color: "#fff",
                  }}
                >
                  {formatNumLocale(Number(params?.unique_traders))}
                </h3>
              </div>
            </div>
          </div>

          <div className="fixed z-20 bottom-0 left-0 w-full bg-black h-5 border-t border-gray-730 text-white text-xxs flex items-center overflow-x-hidden">
            <div className="ticker-carousel" style={{ minWidth: `1000px` }}>
              <div className="w-32 mr-5 shrink-0"></div>
              {[...assets, ...assets, ...assets, ...assets, ...assets].map(
                (asset: Asset, i: number) => (
                  <div key={i} className="mr-6 shrink-0">
                    <span className="">{asset.symbol.replace(/usd/g, "")}</span>

                    <span className="text-gray-450 px-2">
                      {formatPriceLocale(
                        Number(asset.last_price),
                        asset.decimals
                      )}
                    </span>

                    <span
                      className={classNames([
                        asset.change >= 0 ? "text-green-550" : "text-red-550",
                      ])}
                    >
                      {asset.change >= 0 ? "+" : "-"}
                      {Math.abs(asset.change).toFixed(2)}%
                    </span>
                  </div>
                )
              )}
            </div>
          </div>

          <Blink1 />
          <Blink2 />
        </div>
      </div>
    </>
  );
};

// Format utility functions
const formatVolume = (volume: number): string => {
  return "$" + (volume / 1e9).toFixed(2) + "B";
};

const formatOi = (oi: number): string => {
  return "$" + (oi / 1e6).toFixed(2) + "M";
};

const formatTrades = (trades: number): string => {
  return (trades / 1e3).toFixed(0) + "K";
};

const formatNumLocale = (price: number): string => {
  const userLocale = navigator.language;
  return new Intl.NumberFormat(userLocale).format(price);
};

// Header styles
const headerStyle = {
  position: "fixed" as "fixed",
  top: 0,
  left: 0,
  width: "100%",
  //padding: '10px 20px',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 1100,
  backgroundColor: "black",
};

const navLinksStyle = {
  display: "flex",
  gap: "20px",
};

const navLinkStyle = {
  color: "#fff",
  textDecoration: "none",
  fontSize: "13px",
};
