import { PropsWithoutRef } from "react";
import classNames from "classnames";

export const LinkIcon = (
  props: PropsWithoutRef<JSX.IntrinsicElements["svg"]>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(props.className)}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.768-1.768m4-4l4-4a4 4 0 00-5.656-5.656l-1.768 1.768"
    />
  </svg>
);
