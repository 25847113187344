// CancelOrderDialog.tsx

import React, { useState } from "react";
import { Button } from "../components/Button/Button";
import { handleCancelOrder } from "../solidity/CancelOrder";
import { ModalHeader } from "../components/Modal/ModalHeader";

interface CancelOrderDialogProps {
  open: boolean;
  onClose: () => void;
  orderHash: string; // Include the order hash as a prop
  onConfirm: () => void; // Callback function for when the user confirms the cancellation
}

const CancelOrderDialog: React.FC<CancelOrderDialogProps> = ({
  open,
  onClose,
  orderHash,
  onConfirm,
}) => {
  const initiateCancelOrder = async () => {
    console.log(`[initiateCancelOrder] orderHash=${orderHash}`);

    try {
      await handleCancelOrder(orderHash);

      onConfirm();
    } catch (error) {
      console.error("Create order failed:", error);
    }
  };

  return (
    <div>
      <div className="sm:-mt-5 -mt-1">
        <ModalHeader onClose={onClose}>Cancel Order</ModalHeader>
      </div>

      <div className="text-xs">
        <p className='pt-2'>Are you sure you want to cancel this order?</p>
        <p className='pt-2'>
          <span className="text-gray-450">Order Hash:</span> {orderHash}
        </p>
      </div>
      <div className="flex justify-between mt-6">
        <Button variant="green" className='w-32' onClick={initiateCancelOrder}>
          Confirm
        </Button>
        <Button variant="gray" className='w-32' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CancelOrderDialog;
