import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as IconCopy } from "../../assets/icons/svg/copy.svg";

export const CopyField = ({ text, label, courier }: { text: string; label: string; courier?: boolean }) => {
  const [isCopied, setIsCopied] = useState(false);

  // Determine font family based on courier prop
  const fontFamily = courier ? "monospace" : "inherit";

  return (
    <div>
      {label && <div className="text-xxs pb-1 text-gray-150">{label}</div>}
      <div className="w-full border border-gray-650 flex justify-between py-2 px-3 items-center text-white bg-gray-750 text-sm"
           style={{ fontFamily: fontFamily }}> {/* Apply fontFamily here */}
        <div>{text}</div>
        <CopyToClipboard
          text={text}
          onCopy={() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
          }}
        >
          <div>
            {isCopied ? "👍" : <IconCopy className="w-4 text-gray-450 cursor-pointer hover:text-white" />}
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
};
