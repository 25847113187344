import { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";

export interface ModalProps {
  visible: boolean;
  children: ReactNode;
  onClose: () => void;
  align?: "top" | "center";
  mobileAlign?: "top" | "bottom";
  className?: string;
  freeToClose?: boolean;
}

export const Modal = ({
  visible,
  children,
  onClose,
  align = "center",
  mobileAlign = "bottom",
  className = "sm:max-w-xl",
  freeToClose = true,
}: ModalProps) => (
  <Transition.Root show={visible} as={Fragment}>
    <Dialog
      as="div"
      static
      className="modal_dialog fixed inset-0 z-10 overflow-y-auto"
      open={visible}
      onClose={() => {
        freeToClose && onClose();
      }}
    >
      <div
        className={classNames(
          "flex justify-center min-h-screen pb-20 text-center sm:p-0",
          {
            "items-end": mobileAlign === "bottom",
            "items-start": mobileAlign === "top",
            "sm:block": align === "center",
            "sm:items-start": align === "top",
          }
        )}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-opacity bg-black bg-opacity-80" />
        </Transition.Child>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={classNames(
              className,
              "border border-gray-650 bg-black text-white inline-block w-full overflow-hidden text-left align-bottom transition-all transform shadow-xl sm:my-8 sm:align-middle sm:p-10 p-6 pb-10 max-w-screen-xl",
            )}
          >
            {children}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);
