// deserializeMsg.ts

import * as flatbuffers from 'flatbuffers'; 

import { ConciseOrder } from '../fbs/exchange/concise-order';
import { MessageWrapper } from '../fbs/exchange/message-wrapper';
import { Message as MessageType } from '../fbs/exchange/message';
import { OrderBook } from '../fbs/exchange/order-book';
import { Ticker } from '../fbs/exchange/ticker';
import { Trade } from '../fbs/exchange/trade'; 
import { Position } from '../fbs/exchange/position'; 
import { Positions } from '../fbs/exchange/positions'; 


import { ObConcise, ObItemConcise } from "../interfaces/obConcise.interface";
import { TradeConcise } from "../interfaces/tradeConcise.interface";
import { TickerConcise } from "../interfaces/tickerConcise.interface";
import { Position as PositionFE } from "../interfaces/position.interface";

// Assuming MessageType is an enum or a set of constants defining your message types
export enum MsgType {
    OrderBook = "ob",
    Trade = "trade",
    Ticker = "mark_price",
    Position = "position",
    Positions = "positions",
}

export const deserializeMsg = (data: ArrayBuffer): { type: MsgType, data: any } | undefined => {
    const buf = new flatbuffers.ByteBuffer(new Uint8Array(data));
    const msgWrapper = MessageWrapper.getRootAsMessageWrapper(buf);

    switch (msgWrapper.messageType()) {
        case MessageType.OrderBook: {
            let orderBook = new OrderBook();
            msgWrapper.message(orderBook);

            const processItems = (length: number, getItem: (index: number) => ConciseOrder | null): ObConcise['bids' | 'asks'] => {
                const items: ObConcise['bids' | 'asks'] = [];
                for (let i = 0; i < length; i++) {
                    const item = getItem(i);
                    if (item) {
                        items.push({
                            price: item.price().toString(),
                            amount: item.amount().toString(),
                            value: item.value().toString(),
                            total: item.total().toString(),
                            is_yours: item.isYours(),
                        });
                    }
                }
                return items;
            };

            return { 
                type: MsgType.OrderBook, 
                data: {
                    bids: processItems(orderBook.bidsLength(), i => orderBook.bids(i)),
                    asks: processItems(orderBook.asksLength(), i => orderBook.asks(i)),
                }
            };
        }
        case MessageType.Trade: {
            let trade = new Trade();
            msgWrapper.message(trade);

            return { 
                type: MsgType.Trade, 
                data: {
                    Price: trade.price().toString(),
                    Amount: trade.amount().toString(),
                    Asset: trade.asset(),
                    PriceUp: trade.priceUp(),
                    Timestamp: Number(trade.timestamp()), // Assuming timestamp is in milliseconds
                }
            };
        }
        case MessageType.Ticker: {
            let ticker = new Ticker();
            msgWrapper.message(ticker);

            return { 
                type: MsgType.Ticker, 
                data: {
                    symbol: ticker.symbol(),
                    price: ticker.price(),
                }
            };
        }
        case MessageType.Position: {
            let p = new Position();
            msgWrapper.message(p);
            //console.log("[deserializeMsg] positions=", position)
            return { 
                type: MsgType.Position, 
                data: {
                    tx_hash: p.txHash(),        
                    hash: p.hash(),        
                    base_token: p.baseToken(),        
                    asset: p.asset(),
                    side: p.side(),
                    size: Number(p.size()).toString(),
                    value: Number(p.value()).toString(),
                    entry_price: Number(p.entryPrice()).toString(),
                    mark_price: p.markPrice(),
                    last_price: Number(p.lastPrice()).toString(),
                    liquidation_price: Number(p.liquidationPrice()).toString(),
                    margin: Number(p.margin()).toString(),
                    unrealized_pnl: Number(p.unrealizedPnl()),
                    realized_pnl: Number(p.realizedPnl()),
                    funding_rate: Number(p.fundingRate()).toString(),
                    decimals: Number(p.decimals()),
                    amount_dec: Number(p.amountDec()), 
                    margin_dec: Number(p.marginDec()),
                    margin_symbol: p.marginSymbol(), 
                    profit: Number(p.profit()).toString(),
                    loss: Number(p.loss()).toString(),  
                    funding_cost: Number(p.fundingCost()).toString(),
                    ur_funding_cost: Number(p.urFundingCost()).toString(),
                    created_at: p.createdAt(),
                    liquidated: p.liquidated(),
                    is_cross: p.isCross(),
                    cross_margin: Number(p.crossMargin()).toString(),
                }
            };
        }
        // case MessageType.Positions: {
        //     let positions = new Positions();
        //     msgWrapper.message(positions);

        //     console.log("[deserializeMsg] positions=", positions)

        //     return { 
        //         type: MsgType.Positions, 
        //         data: positions.positions
        //     };
        // }
        case MessageType.Positions: {
            let positions = new Positions();
            msgWrapper.message(positions);

            const processItems = (length: number, getItem: (index: number) => Position | null): PositionFE[] => {
                const items: PositionFE[] = [];
                for (let i = 0; i < length; i++) {
                    const p = getItem(i);
                    //console.log("[deserializeMsg] p=", p);
                    if (p) {
                        let deserialized = {
                            tx_hash: p.txHash(),        
                            hash: p.hash(),        
                            base_token: p.baseToken(),        
                            asset: p.asset(),
                            side: p.side(),
                            size: Number(p.size()).toString(),
                            value: Number(p.value()).toString(),
                            entry_price: Number(p.entryPrice()).toString(),
                            mark_price: p.markPrice(),
                            last_price: Number(p.lastPrice()).toString(),
                            liquidation_price: Number(p.liquidationPrice()).toString(),
                            margin: Number(p.margin()).toString(),
                            unrealized_pnl: Number(p.unrealizedPnl()),
                            realized_pnl: Number(p.realizedPnl()),
                            funding_rate: Number(p.fundingRate()).toString(),
                            decimals: Number(p.decimals()),
                            amount_dec: Number(p.amountDec()), 
                            margin_dec: Number(p.marginDec()),
                            margin_symbol: p.marginSymbol(), 
                            profit: Number(p.profit()).toString(),
                            loss: Number(p.loss()).toString(),  
                            funding_cost: Number(p.fundingCost()).toString(),
                            ur_funding_cost: Number(p.urFundingCost()).toString(),
                            created_at: p.createdAt(),
                            liquidated: p.liquidated(),
                            is_cross: p.isCross(),
                            cross_margin: Number(p.crossMargin()).toString(),
                        }
                        //console.log("[deserializeMsg] deserialized p=", deserialized);
                        items.push(deserialized);
                    }
                }
                return items;
            };
            let deserializedPositions = processItems(positions.positionsLength(), i => positions.positions(i) )
            //console.log("[deserializeMsg] positions=", deserializedPositions);
            return { 
                type: MsgType.Positions,  
                data: deserializedPositions,
            };
        }
        default:
            console.error("Unknown message type");
            return undefined;
    }
};

export default deserializeMsg;