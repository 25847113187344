// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class Position {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Position {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPosition(bb:flatbuffers.ByteBuffer, obj?:Position):Position {
  return (obj || new Position()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPosition(bb:flatbuffers.ByteBuffer, obj?:Position):Position {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Position()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

txHash():string
txHash(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
txHash(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "";
}

hash():string
hash(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
hash(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "";
}

baseToken():string
baseToken(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
baseToken(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "";
}

asset():string
asset(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
asset(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "";
}

side():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

size():string|null
size(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
size(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

entryPrice():string|null
entryPrice(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
entryPrice(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

markPrice():number {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

lastPrice():string|null
lastPrice(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
lastPrice(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "0";
}

liquidationPrice():string|null
liquidationPrice(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
liquidationPrice(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "0";
}

margin():string|null
margin(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
margin(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "0";
}

unrealizedPnl():string
unrealizedPnl(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
unrealizedPnl(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "0";
}

realizedPnl():string
realizedPnl(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
realizedPnl(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "0";
}

fundingRate():string|null
fundingRate(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
fundingRate(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

decimals():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 34);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

amountDec():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 36);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

marginDec():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 38);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

marginSymbol():string
marginSymbol(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
marginSymbol(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 40);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "";
}

profit():string|null
profit(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
profit(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 42);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

loss():string|null
loss(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
loss(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 44);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

fundingCost():string|null
fundingCost(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
fundingCost(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 46);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

urFundingCost():string|null
urFundingCost(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
urFundingCost(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 48);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

createdAt():string
createdAt(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
createdAt(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 50);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : "";
}

liquidated():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 52);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

isCross():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 54);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

crossMargin():string|null
crossMargin(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
crossMargin(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 56);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startPosition(builder:flatbuffers.Builder) {
  builder.startObject(27);
}

static addTxHash(builder:flatbuffers.Builder, txHashOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, txHashOffset, 0);
}

static addHash(builder:flatbuffers.Builder, hashOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, hashOffset, 0);
}

static addBaseToken(builder:flatbuffers.Builder, baseTokenOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, baseTokenOffset, 0);
}

static addAsset(builder:flatbuffers.Builder, assetOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, assetOffset, 0);
}

static addSide(builder:flatbuffers.Builder, side:boolean) {
  builder.addFieldInt8(4, +side, +false);
}

static addSize(builder:flatbuffers.Builder, sizeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, sizeOffset, 0);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, valueOffset, 0);
}

static addEntryPrice(builder:flatbuffers.Builder, entryPriceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, entryPriceOffset, 0);
}

static addMarkPrice(builder:flatbuffers.Builder, markPrice:number) {
  builder.addFieldFloat64(8, markPrice, 0.0);
}

static addLastPrice(builder:flatbuffers.Builder, lastPriceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, lastPriceOffset, 0);
}

static addLiquidationPrice(builder:flatbuffers.Builder, liquidationPriceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, liquidationPriceOffset, 0);
}

static addMargin(builder:flatbuffers.Builder, marginOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, marginOffset, 0);
}

static addUnrealizedPnl(builder:flatbuffers.Builder, unrealizedPnlOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, unrealizedPnlOffset, 0);
}

static addRealizedPnl(builder:flatbuffers.Builder, realizedPnlOffset:flatbuffers.Offset) {
  builder.addFieldOffset(13, realizedPnlOffset, 0);
}

static addFundingRate(builder:flatbuffers.Builder, fundingRateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(14, fundingRateOffset, 0);
}

static addDecimals(builder:flatbuffers.Builder, decimals:bigint) {
  builder.addFieldInt64(15, decimals, BigInt('0'));
}

static addAmountDec(builder:flatbuffers.Builder, amountDec:bigint) {
  builder.addFieldInt64(16, amountDec, BigInt('0'));
}

static addMarginDec(builder:flatbuffers.Builder, marginDec:bigint) {
  builder.addFieldInt64(17, marginDec, BigInt('0'));
}

static addMarginSymbol(builder:flatbuffers.Builder, marginSymbolOffset:flatbuffers.Offset) {
  builder.addFieldOffset(18, marginSymbolOffset, 0);
}

static addProfit(builder:flatbuffers.Builder, profitOffset:flatbuffers.Offset) {
  builder.addFieldOffset(19, profitOffset, 0);
}

static addLoss(builder:flatbuffers.Builder, lossOffset:flatbuffers.Offset) {
  builder.addFieldOffset(20, lossOffset, 0);
}

static addFundingCost(builder:flatbuffers.Builder, fundingCostOffset:flatbuffers.Offset) {
  builder.addFieldOffset(21, fundingCostOffset, 0);
}

static addUrFundingCost(builder:flatbuffers.Builder, urFundingCostOffset:flatbuffers.Offset) {
  builder.addFieldOffset(22, urFundingCostOffset, 0);
}

static addCreatedAt(builder:flatbuffers.Builder, createdAtOffset:flatbuffers.Offset) {
  builder.addFieldOffset(23, createdAtOffset, 0);
}

static addLiquidated(builder:flatbuffers.Builder, liquidated:boolean) {
  builder.addFieldInt8(24, +liquidated, +false);
}

static addIsCross(builder:flatbuffers.Builder, isCross:boolean) {
  builder.addFieldInt8(25, +isCross, +false);
}

static addCrossMargin(builder:flatbuffers.Builder, crossMarginOffset:flatbuffers.Offset) {
  builder.addFieldOffset(26, crossMarginOffset, 0);
}

static endPosition(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPosition(builder:flatbuffers.Builder, txHashOffset:flatbuffers.Offset, hashOffset:flatbuffers.Offset, baseTokenOffset:flatbuffers.Offset, assetOffset:flatbuffers.Offset, side:boolean, sizeOffset:flatbuffers.Offset, valueOffset:flatbuffers.Offset, entryPriceOffset:flatbuffers.Offset, markPrice:number, lastPriceOffset:flatbuffers.Offset, liquidationPriceOffset:flatbuffers.Offset, marginOffset:flatbuffers.Offset, unrealizedPnlOffset:flatbuffers.Offset, realizedPnlOffset:flatbuffers.Offset, fundingRateOffset:flatbuffers.Offset, decimals:bigint, amountDec:bigint, marginDec:bigint, marginSymbolOffset:flatbuffers.Offset, profitOffset:flatbuffers.Offset, lossOffset:flatbuffers.Offset, fundingCostOffset:flatbuffers.Offset, urFundingCostOffset:flatbuffers.Offset, createdAtOffset:flatbuffers.Offset, liquidated:boolean, isCross:boolean, crossMarginOffset:flatbuffers.Offset):flatbuffers.Offset {
  Position.startPosition(builder);
  Position.addTxHash(builder, txHashOffset);
  Position.addHash(builder, hashOffset);
  Position.addBaseToken(builder, baseTokenOffset);
  Position.addAsset(builder, assetOffset);
  Position.addSide(builder, side);
  Position.addSize(builder, sizeOffset);
  Position.addValue(builder, valueOffset);
  Position.addEntryPrice(builder, entryPriceOffset);
  Position.addMarkPrice(builder, markPrice);
  Position.addLastPrice(builder, lastPriceOffset);
  Position.addLiquidationPrice(builder, liquidationPriceOffset);
  Position.addMargin(builder, marginOffset);
  Position.addUnrealizedPnl(builder, unrealizedPnlOffset);
  Position.addRealizedPnl(builder, realizedPnlOffset);
  Position.addFundingRate(builder, fundingRateOffset);
  Position.addDecimals(builder, decimals);
  Position.addAmountDec(builder, amountDec);
  Position.addMarginDec(builder, marginDec);
  Position.addMarginSymbol(builder, marginSymbolOffset);
  Position.addProfit(builder, profitOffset);
  Position.addLoss(builder, lossOffset);
  Position.addFundingCost(builder, fundingCostOffset);
  Position.addUrFundingCost(builder, urFundingCostOffset);
  Position.addCreatedAt(builder, createdAtOffset);
  Position.addLiquidated(builder, liquidated);
  Position.addIsCross(builder, isCross);
  Position.addCrossMargin(builder, crossMarginOffset);
  return Position.endPosition(builder);
}
}
