// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { Position } from '../exchange/position';


export class Positions {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Positions {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPositions(bb:flatbuffers.ByteBuffer, obj?:Positions):Positions {
  return (obj || new Positions()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPositions(bb:flatbuffers.ByteBuffer, obj?:Positions):Positions {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Positions()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

positions(index: number, obj?:Position):Position|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new Position()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

positionsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startPositions(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addPositions(builder:flatbuffers.Builder, positionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, positionsOffset, 0);
}

static createPositionsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPositionsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endPositions(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPositions(builder:flatbuffers.Builder, positionsOffset:flatbuffers.Offset):flatbuffers.Offset {
  Positions.startPositions(builder);
  Positions.addPositions(builder, positionsOffset);
  return Positions.endPositions(builder);
}
}
